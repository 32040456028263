import React from "react";
import { MDBRow, MDBCol, MDBContainer } from "mdbreact";
import BasicButton from "../../SharedComponents/BasicButton/BasicButton";
import SelectField from "../../SharedComponents/SelectField/SelectField";
import BasicLabel from "../../SharedComponents/BasicLabel/BasicLabel";
import { withUserContext } from "../../../contexts/UserContext";
import "./ImportGL.scss";
import CircularProgress from "@material-ui/core/CircularProgress";
import { AppService } from "../../../../src/services/AppService";
import PostWatchService from "../../../../src/services/service";
import * as Constants from "../../../constants/constants";

class ImportGL extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      postInitiated: false,
      fileData: null,
      fileName: null,
      isSubmit: false,
      showError: false,
      loadingExcel: false,
    };
  }

  postImportGL = () => {
    this.setState({ isSubmit: true });
    PostWatchService.postDataBuffer(
      Constants.postWatchServiceBaseUrl +
        `/postimportgldetails?userId=` +
        this.props?.userContext?.user_profile?.user_id,
      this.state.fileData,
      this.props.userContext?.active_tenant?.tenant_id
    ).then(
      (response) => {
        if (response.data.error) {
          this.setState({ isSubmit: false });
          AppService.showMessageModal("fail");
        } else {
          AppService.showMessageModal("success");
          this.setState({ isSubmit: false });
        }
      },
      (err) => {
        this.setState({ isSubmit: false });
        console.log("Post agency details error: " + err);
      }
    );
  };

  uploadFile(file) {
    this.setState({ fileData: null, fileName: null, showError: false });
    try {
      const reader = new FileReader();
      if (file?.size > 5242880) {
        this.setState({ showError: true });
        return;
      }

      var allowedExtensions = /(\.xl|\.xls|\.sheet|\.xlsx)$/i;
      if (!allowedExtensions.exec(file.type)) {
        this.setState({ showError: true });
        return false;
      }
      reader.onload = () => {
        if (reader.readyState === 2) {
          let result = reader.result.split(",")?.[1];
          this.setState({ fileData: result, fileName: file.name });
          this.props?.fieldChanged(true, "change");
        }
      };
      reader.readAsDataURL(file);
    } catch (error) {
      console.log(error);
      this.setState({ fileData: null, fileName: null });
    }
  }
  downloadTemplate = () => {
    this.setState({ loadingExcel: true });
    PostWatchService.getDataParams(
      Constants.postWatchServiceBaseUrl + "/getgldetailstemplate",
      "",
      this.props.userContext?.active_tenant?.tenant_id
    ).then(
      (response) => {
        this.downloadUrl(response.data, "Show");
        this.setState({ loadingExcel: false });
      },
      (err) => {
        this.setState({ loadingExcel: false });
      }
    );
  };

  downloadUrl = (url, reportName) => {
    window.open(url, "_blank");
  };

  render() {
    return (
      <div>
        <MDBContainer className="importGLContainer">
          <MDBRow className="download-template-row">
            <BasicButton
              text={
                this.state.loadingExcel ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  "Template"
                )
              }
              icon={"download"}
              onClick={this.downloadTemplate}
            />
          </MDBRow>
          <MDBRow>
            <span>Excel sheet must contail following specification:</span>
            <ol className="specificationsList">
              <li>First row must contain headings.</li>
              <li>There must be no empty rows.</li>
              <li>
                Each row must have a Season, Series Title, and Episode #. Season
                and Series Title’s must match that of an existing Show Season.
              </li>
              <li>Episodes will be ordered ascending by their GL #.</li>
              <li>
                Columns must be in following order:
                <ol type="a">
                  <li>Season</li>
                  <li>Series Title</li>
                  <li>Production #</li>
                  <li>Episode Title</li>
                </ol>
              </li>
            </ol>
          </MDBRow>
          <div className="file-text">
            <span>Select File(.csv,.xlxs)</span>
          </div>

          <div className="file-upload">
            <BasicButton
              variant="outlined"
              icon="paperclip"
              type="inline"
              inputType="file"
              id={"imporstseaonfile"}
              uploadFile={(file) => this.uploadFile(file)}
            />

            <BasicLabel text={"(.csv,.xlxs, up to 10mb)"} />
          </div>

          <span className="filename">{this.state.fileName}</span>
          <span className="error-upload">
            {this.state.showError &&
              "Incorrect file type. Please select only .xlsx, .csv' file types to upload"}
          </span>
          <MDBRow className="import-gl-btn-row">
            <MDBCol md={8}></MDBCol>
            <MDBCol md={2}>
              <BasicButton
                className="importButton"
                text={
                  this.state.isSubmit ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : (
                    "Import"
                  )
                }
                variant="contained"
                type="inline"
                disabled={!this.state.fileName}
                onClick={this.postImportGL.bind(this)}
              />
            </MDBCol>
            <MDBCol md={2}>
              <BasicButton
                type="inline"
                variant="outlined"
                text={"Cancel"}
                onClick={this.props.onClose}
                disabled={false}
              />
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </div>
    );
  }
}

export default withUserContext(ImportGL);
