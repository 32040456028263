import React from "react";
import TableContainer from "@material-ui/core/TableContainer";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { MDBIcon } from "mdbreact";
import "./shared.scss";
import CircularProgress from "@material-ui/core/CircularProgress";
import Chip from "@material-ui/core/Chip";
import BasicButton from "../../components/SharedComponents/BasicButton/BasicButton";
import TableFilters from "./TableFilters";
import TableInlineEdits from "./TableInlineEdits";
import _ from "lodash";
import BasicTextField from "./BasicTextField/BasicTextField";
// import BasicCheckbox from "./BasicCheckbox/BasicCheckbox";

class TableComponent extends React.Component {
  constructor(props) {
    super(props);
    var headings = this.props?.config?.headings;
    var colArr = {};
    var dataTypeArr = {};
    var selectOptionsArr = {};
    headings.forEach((heading, index) => {
      colArr[heading.headingLabel] = "";
      dataTypeArr[this.props?.config?.dataNodes[index]] = heading.inputType;
      if (heading.inputType == "select" || heading.inputType === "searchselect")
        selectOptionsArr[this.props?.config?.dataNodes[index]] =
          heading.selectOptions;
    });
    this.state = {
      inputValues: colArr,
      filterValues: [],
      listEdits: JSON.parse(JSON.stringify(this.props?.list || [])),
      editInputValues: [],
      dataTypeValues: dataTypeArr,
      selectOptionValues: selectOptionsArr,
      dropDownValues: [],
    };
  }

  componentDidMount() {
    //var headings = this.props?.config?.headings;
    // var colArr = {};
    // var dataTypeArr = {};
    // var selectOptionsArr = {};
    // headings.forEach((heading) => {
    //     colArr[heading.headingLabel] = "";
    //     dataTypeArr[this.props?.config?.dataNodes[index]] = heading.inputType ;
    //     if(heading.inputType == "select")
    //     selectOptionsArr[this.props?.config?.dataNodes[index]] = heading.selectOptions;
    // });
    // this.setState({
    //     inputValues : colArr,
    //     filterValues: [],
    //     listEdits: JSON.parse(JSON.stringify(this.props?.list || [])),
    //     dataTypeValues: dataTypeArr,
    //     selectOptionValues: selectOptionsArr
    // });
    let newInputValues = { ...this.state.newItem };
    newInputValues["currency_exchange"] = this.props?.defaultCompanyCurrency;
    this.setState({ newItem: newInputValues});
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.list !== this.props.list) {
      this.setState({
        listEdits: JSON.parse(JSON.stringify(this.props?.list || [])),
      });
    }
    if (prevProps.defaultCompanyExchangeRate !== this.props.defaultCompanyExchangeRate) {      
      this.setState({
        newItem: {
          quantity: null,
          subtotal: null,
          subtotal_value: null,
          total_cost: null,
          total_cost_value: null,
          unit_cost: null,         
        }
      },()=>{
        let newInputValues = { ...this.state.newItem };
      newInputValues["currency_exchange"] = this.props?.defaultCompanyCurrency;
        this.setState({ newItem: newInputValues})
      });
    }
    let selectOptionsArr = [];
    this.props?.config?.headings?.map((heading, index) => {
      if (heading.inputType === "searchselect") {
        if (
          prevState?.selectOptionValues[this.props?.config.dataNodes[index]] !==
          prevProps?.config?.headings[index].selectOptions
        ) {
          selectOptionsArr[this.props?.config?.dataNodes[index]] =
            heading.selectOptions;
          this.setState({ selectOptionValues: selectOptionsArr });
        }
      }
    });
  }

  addFilterChangeHandler = (index, name, type, value) => {
    var inputValues = this.state.inputValues;
    var filterValues = this.state.filterValues;
    var dropDownValues = this.state.dropDownValues;
    if (type === "dropdown") {
      const dropDownValue = { value: inputValues[name], label: value };
      dropDownValues.push(dropDownValue);
    } else if (type === "autocomplete") {
      const dropDownValue = { value: inputValues[name].value, label: value };
      dropDownValues.push(dropDownValue);
    }
    if (filterValues.length > 0) {
      let presentInd = filterValues.findIndex(
        (item) => item.split(":")[0] === name
      );
      if (presentInd != -1) {
        if (type === "daterange") {
          if (inputValues[name] != "") {
            filterValues[presentInd] =
              name + ": " + value + " : " + inputValues[name];
          }
        } else {
          filterValues[presentInd] = name + ": " + value;
        }
      } else {
        if (type === "daterange") {
          if (inputValues[name] != "") {
            filterValues.push(name + ": " + value + " : " + inputValues[name]);
          }
        } else {
          filterValues.push(name + ": " + value);
        }
      }
    } else {
      if (type === "daterange") {
        if (inputValues[name] != "") {
          filterValues.push(name + ": " + value + " : " + inputValues[name]);
        }
      } else {
        filterValues.push(name + ": " + value);
      }
    }
    inputValues[name] = "";
    this.setState({
      inputValues: inputValues,
      filterValues: filterValues,
      dropDownValues: dropDownValues,
    });
    var filtersNone = false;
    if (filterValues.length == 0) filtersNone = true;
    this.props.applyFilter(filterValues, filtersNone, dropDownValues);
  };

  filterChangeHandler = (index, name, value, filter) => {
    var inputValues = this.state.inputValues;
    inputValues[name] = value;
    this.setState({
      inputValues: inputValues,
    });
  };

  onChangeNewItemHandler = (value, index, nodeName) => {
    let newInputValues = { ...this.state.newItem };
    newInputValues[nodeName] = value;
    if (
      this.props.autoCalculate &&
      ["quantity", "unit_cost", "currency_exchange","adj_curr_exch_rate","currency_code"].includes(nodeName)
    ) {
      if (newInputValues["quantity"] && newInputValues["unit_cost"]) {
        newInputValues["subtotal"] =
          newInputValues["quantity"] * newInputValues["unit_cost"];
        newInputValues["subtotal"] = Number.parseFloat(
          newInputValues["subtotal"]
        ).toFixed(2);
        newInputValues["subtotal_value"] = newInputValues["subtotal"];
      }
      if (newInputValues["subtotal"] ) {
        
        let currency = _.find(this.props.currencies, (currency) => {
          if (nodeName === 'currency_code' || nodeName==='adj_curr_exch_rate') {
            return currency.currency_code === (newInputValues["currency_code"] || currency.currency_code);
          } else {
            return currency.currency_exchange === (newInputValues["currency_exchange"] || currency.currency_exchange);
          }
        });
        
        // if (currency) {
        // If exchange_rate is not selected, then consider as 1
        if(nodeName==='adj_curr_exch_rate'){
          newInputValues["total_cost"] =
          newInputValues["subtotal"] * (newInputValues[nodeName]!=null ? newInputValues[nodeName] : currency?.exchange_rate);
          newInputValues["currency_exchange"]=null;
        }else{
          newInputValues["total_cost"] =
          newInputValues["subtotal"] * (currency?.exchange_rate || (this.props?.defaultCompanyExchangeRate ?? 1));
          newInputValues["adj_curr_exch_rate"]=null;
        }
     
        newInputValues["total_cost"] = Number.parseFloat(
          newInputValues["total_cost"]
        ).toFixed(2);
        newInputValues["total_cost_value"] = newInputValues["total_cost"];
        // }
      }
    }
    this.setState({ newItem: newInputValues});
  };

  handleAddNewItem = () => {
    this.props?.handleAddNewItem(this.state.newItem);
    if (this.props?.config?.isClearFields) {
      this.setState({ newItem: {} });
    }
  };

  handleInlineEditChange = (value, index, nodeName, type = null) => {
    var editInputValues = [...this.state.listEdits];
    editInputValues[index][nodeName] = value;
    if (
      this.props.autoCalculate &&
      ["quantity", "unit_cost", "currency_exchange","adj_curr_exch_rate"].includes(nodeName)
    ) {
      editInputValues[index].subtotal =
        editInputValues[index].quantity * editInputValues[index]?.unit_cost;
      editInputValues[index].subtotal = Number.parseFloat(
        editInputValues[index].subtotal
      ).toFixed(2);
      editInputValues[index].subtotal_value = editInputValues[index].subtotal;
      let currency = _.find(this.props.currencies, {
        currency_exchange: editInputValues[index].currency_exchange,
      });
      // if (currency) {
      // If exchange_rate is not selected, then consider as 1
      if(nodeName === 'adj_curr_exch_rate'){
        editInputValues[index].total_cost =
        editInputValues[index]?.subtotal * (value || 1);
        editInputValues[index]["currency_exchange"]=null;
      }else{
        editInputValues[index].total_cost =
        editInputValues[index]?.subtotal * (currency?.exchange_rate || 1);
        editInputValues[index]["adj_curr_exch_rate"]=null;
      }
     
      editInputValues[index].total_cost = Number.parseFloat(
        editInputValues[index].total_cost
      ).toFixed(2);
      editInputValues[index].total_cost_value =
        editInputValues[index].total_cost;
      // }
    }
    this.setState({ listEdits: editInputValues }, () => {
      this.props?.checkUpdatedValues
        ? this.props?.checkUpdatedValues(this.state.listEdits, index)
        : null;
    });
  };

  handleFilterDelete = (removeNode, index) => {
    var filterValues = this.state.filterValues;
    filterValues.splice(index, 1);
    this.setState({ filterValues: filterValues });
    var filtersNone = false;
    if (filterValues.length == 0) filtersNone = true;
    this.props.applyFilter(filterValues, filtersNone);
  };

  clearFilter = () => {
    this.setState({ filterValues: [] });
    this.props.clearFilterHandler();
  };
  render() {
    return (
      <>
        <div>
          {this.props?.config?.filters && (
            <>
              <div
                style={{ display: "flex" }}
                className="columnFilters mb-1 p-0"
              >
                {this.state.filterValues &&
                  this.state.filterValues?.map((item, index) => (
                    <Chip
                      label={item}
                      onDelete={this.handleFilterDelete.bind(this, item, index)}
                    />
                  ))}
                <div style={{ marginLeft: "auto" }}>
                  <BasicButton
                    type="inline"
                    variant="outlined"
                    icon=""
                    text="Clear Filters"
                    onClick={() => this.clearFilter()}
                  />
                </div>
              </div>

              <div class="filters-container d-flex flex-wrap p-2 bgColorWhite mb-2">
                {this.props?.config?.filters?.map((heading, index) => (
                  <div style={{ width: heading?.width }}>
                    <TableFilters
                      inputType={heading.inputType}
                      name={heading.headingLabel}
                      placeholder={heading.placeholder}
                      inputValue={this.state.inputValues[heading.headingLabel]}
                      options={this.props?.options[heading?.selectOptions]}
                      filterChangeHandler={this.filterChangeHandler.bind(
                        this,
                        index,
                        heading.headingLabel
                      )}
                      addFilterChangeHandler={this.addFilterChangeHandler.bind(
                        this,
                        index,
                        heading.headingLabel,
                        heading.inputType
                      )}
                    />{" "}
                  </div>
                ))}
              </div>
            </>
          )}

          <div
            className={
              this.props?.maxHeight
                ? "tableContainer " + "max-height-" + this.props?.maxHeight
                : "tableContainer"
            }
          >
            <TableContainer
              component={Paper}
              className={
                this.props?.tableMode == "light"
                  ? "bg-white overflow-x-unset"
                  : "bg-light overflow-x-unset"
              }
            >
              <Table
                id={this.props.className + "-table"}
                //stickyHeader
                aria-label="collapsible table"
                size="small"
                style={{ tableLayout: "fixed" }}
              >
                <colgroup>
                  {this.props?.config?.headings?.map((heading, index) => (
                    <col
                      key={"colWidth_" + index}
                      width={
                        heading.width
                          ? heading.width
                          : 100 / this.props?.config?.headings?.length + "%"
                      }
                    />
                  ))}
                </colgroup>
                {this.props?.config?.isSearchBar ? (
                  <>
                    <TableHead class="searchHeaders">
                      <TableRow key={this.props.className + "-searchHeaderRow"}>
                        {this.props?.config?.headings?.map((heading, index) => (
                          <TableCell
                            key={
                              this.props.className +
                              "-" +
                              index +
                              "-" +
                              heading.headingLabel
                            }
                            className="searchHeaderRows"
                          >
                            {index === 0
                              ? "Search Results for :" +
                                '"' +
                                this.props.searchText +
                                '"'
                              : ""}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableHead class="searchHeadersSecond">
                      <TableRow
                        key={this.props.className + "-searchHeaderSecondRow"}
                      >
                        {this.props?.config?.headings?.map((heading, index) => (
                          <TableCell
                            key={this.props.className + "-" + index}
                            className="searchHeadersSecondRows"
                          >
                            {index === 0
                              ? this.props.resultsCount +
                                " search results for " +
                                "[" +
                                this.props.searchType +
                                "]"
                              : ""}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                  </>
                ) : null}
                <TableHead
                  id={this.props.className + "-tablehead"}
                  className={
                    this.props?.tableMode == "light" ? "bg-light" : "bg-dark"
                  }
                >
                  {this.props?.config?.filters ? (
                    <TableRow
                      className={
                        this.state.filterValues?.length > 0
                          ? "columnFilters d-block"
                          : "d-none"
                      }
                    >
                      {/* <TableCell colSpan={this.props?.config?.headings?.length || 1} key={this.props.className + "-chips-0" } className="FW800" style ={{padding : "4px"}}  > */}

                      {/* </TableCell> */}
                    </TableRow>
                  ) : (
                    <></>
                  )}
                  <TableRow>
                    {this.props?.config?.headings?.map((heading, index) => (
                      <TableCell
                        key={this.props.className + "-" + index}
                        style={{ padding: "4px" }}
                        className={
                          this.props?.tableMode == "light"
                            ? "bg-light FW800"
                            : "bg-dark FW800"
                        }
                      >
                        <span
                          className="action-icon"
                          onClick={() =>
                            this.props?.sortCallback(heading.headingLabel)
                          }
                        >
                          <b>
                            {" "}
                            {heading.icon ? (
                              <div>
                                {heading.headingLabel}
                                <MDBIcon icon={heading.icon} />
                              </div>
                            ) : (
                              heading.headingLabel
                            )}{" "}
                          </b>
                        </span>
                      </TableCell>
                    ))}
                    {this.props?.config?.actions && (
                      <TableCell
                        key={
                          this.props.className +
                          "-" +
                          this.props?.config?.headings?.length
                        }
                        className={
                          this.props?.tableMode == "light"
                            ? "bg-light FW800"
                            : "bg-dark FW800"
                        }
                        style={{ padding: "4px" }}
                      ></TableCell>
                    )}
                  </TableRow>
                </TableHead>
                {this.props?.isLoading ? (
                  <div className="tableLoader">
                    <CircularProgress />
                  </div>
                ) : (
                  <TableBody key={this.props.className + "body"}>
                    {this.props?.list?.length > 0 &&
                      this.props?.list?.map((dataItem, rowIndex) => (
                        <TableRow
                          id={
                            this.props.className +
                            dataItem[this.props.config.primaryKey]
                          }
                          key={
                            this.props.className +
                            "-" +
                            dataItem[this.props.config.primaryKey] +
                            "-" +
                            rowIndex
                          }
                          onClick={
                            this.props?.flag
                              ? (e) => {
                                  this.props?.checking(e, dataItem, rowIndex);
                                }
                              : null
                          }
                        >
                          {this.props?.config?.dataNodes?.map((node, index) =>
                            node == "check" ? (
                              <TableCell className="FW800">
                                <input
                                  type="checkbox"
                                  checked={dataItem[node]}
                                  disabled={dataItem["is_avail"] == 0 || false}
                                  onClick={(event, newValue) => {
                                    dataItem[node] = event.target.checked;
                                    this.props.arrayCheckUncheck(
                                      event.target.checked,
                                      dataItem
                                    );
                                  }}
                                ></input>
                              </TableCell>
                            ) : (
                              <TableCell
                                key={
                                  this.props.className +
                                  "-" +
                                  dataItem[this.props?.config?.primaryKey] +
                                  "-" +
                                  dataItem[node] +
                                  "-" +
                                  index
                                }
                                className={
                                  (this.props?.config?.borders?.includes(node)
                                    ? "border-left-1-secondary "
                                    : "") +
                                  (this.props?.config?.changeColourToRed?.includes(
                                    dataItem[node]
                                  )
                                    ? "textColorRed "
                                    : "") +
                                  (this.props?.config?.changeColourToGreen?.includes(
                                    dataItem[node]
                                  )
                                    ? "textColorGreen "
                                    : "") +
                                  //(this.props?.config?.dataNodes.length == index+1 ? "border-right-0 " : ""  ) +
                                  "border-ver-1-secondary padding-5"
                                }
                              >
                                {dataItem["editing"] &&
                                this.props?.config?.inlineEdits?.some(
                                  (e) => e.dataNode === node
                                ) ? (
                                  <> </>
                                ) : node == "groups" ? (
                                  dataItem[node]?.map((item) => (
                                    <div className="groupsStyles">{item}</div>
                                  ))
                                ) : node == "label" ? (
                                  <div
                                    className="labelNames"
                                    onClick={(e) =>
                                      this.props.editData(dataItem, node)
                                    }
                                  >
                                    {dataItem[node]}
                                  </div>
                                ) : this.props?.config?.hyperlinks?.includes(
                                    node
                                  ) ? (
                                  <a
                                    href="javascript:void(0);"
                                    onClick={(e) =>
                                      this.props.hyperLinkNavigationCallback(
                                        dataItem,
                                        node
                                      )
                                    }
                                  >
                                    {dataItem[node]}
                                  </a>
                                ) : // :
                                // this.props?.config?.inlineCheckbox?.includes(node) ?
                                //     (
                                //         <BasicCheckbox
                                //             id={"cb-" + dataItem[node]}
                                //             label={dataItem[node] || ""}
                                //             checked={this.state.listEdits?.[rowIndex]?.[dataItem[node]]}
                                //             className={"cb-" + dataItem[node]}
                                //             onChange={(e) => this.props?.handleInlineCheckboxEdit((e.target.checked ? true : false), dataItem)}
                                //         />
                                //     )
                                this.props?.config?.toggleFields?.includes(
                                    node
                                  ) ? (
                                  dataItem[node] != null ? (
                                    <MDBIcon
                                      icon={
                                        dataItem[node] == true
                                          ? "check"
                                          : "times"
                                      }
                                      className={
                                        dataItem[node] == true
                                          ? "text-success"
                                          : "text-dark"
                                      }
                                    />
                                  ) : (
                                    <> </>
                                  )
                                ) : this.props?.config?.nodeFields?.some(
                                    (configItem) => configItem.column === node
                                  ) ? (
                                  this.props?.config?.nodeFields?.map(
                                    (nodeItem) =>
                                      nodeItem.column === node ? (
                                        <MDBIcon
                                          icon={
                                            dataItem[node] !== 0
                                              ? nodeItem.icon
                                              : ""
                                          }
                                          onClick={
                                            nodeItem.column === "notes"
                                              ? this.props?.handlePopoverClick
                                              : nodeItem.icon === "flag"
                                              ? this.props?.handleFlagPopover
                                              : null
                                          }
                                        />
                                      ) : (
                                        <></>
                                      )
                                  )
                                ) : this.props?.config?.fileAttachments?.includes(
                                    node
                                  ) ? (
                                  <BasicButton
                                    variant="outlined"
                                    icon="paperclip"
                                    type="inline"
                                    inputType="file"
                                    id={
                                      this.props?.objectType +
                                      "-" +
                                      this.props?.name
                                    }
                                    uploadFile={this.props?.handleFileSelect}
                                  />
                                ) : this.state.selectOptionValues?.[node] &&
                                  !this.props?.config?.searchFields?.includes(
                                    node
                                  ) ? (
                                  <>{
                                    this.state.selectOptionValues?.[node].map(
                                      (item) => {
                                       if (item.value == dataItem[node]){
                                          return (
                                            <>
                                              {" "}
                                              {item?.label
                                                ? item.label
                                                : item.text}{" "}
                                            </>
                                          );
                                        }
                                      }
                                    )}
                                  </>
                                ) : this.props?.config?.searchFields?.includes(
                                    node
                                  ) ? (
                                  <>
                                    {this.props?.list?.map((item) => {
                                      if (item[node] == dataItem[node])
                                        return <>{item[node + "_name"]}</>;
                                    })}
                                  </>
                                ) : this.props?.config?.buttonFields?.some(
                                    (configItem) => configItem.column === node
                                  ) ? (
                                  this.props?.config?.buttonFields?.map(
                                    (nodeItem) =>
                                      nodeItem.column === node ? (
                                        <div className="buttonField-label">
                                          <BasicButton
                                            variant="contained"
                                            id={
                                              this.props?.objectType +
                                              "-" +
                                              this.props?.name
                                            }
                                            onClick={
                                              nodeItem.column === "eps"
                                                ? this.props
                                                    ?.handleClickEPSModal
                                                : nodeItem.column === "is_fr"
                                                ? () =>
                                                    this.props?.handleOnButtonClick(
                                                      dataItem
                                                    )
                                                : null
                                            }
                                            text={
                                              dataItem[node]
                                                ? dataItem[node]
                                                : nodeItem.column
                                            }
                                            icon={
                                              nodeItem.icon === ""
                                                ? ""
                                                : nodeItem.icon
                                            }
                                          />
                                        </div>
                                      ) : (
                                        <></>
                                      )
                                  )
                                ) : this.props?.config?.textWithbuttonFields?.some(
                                    (configItem) => configItem.column === node
                                  ) ? (
                                  this.props?.config?.textWithbuttonFields?.map(
                                    (nodeItem) =>
                                      nodeItem.column === node ? (
                                        <div style={{ display: "flex" }}>
                                          <span>{nodeItem.column}</span>
                                          <div className="buttonField-label">
                                            <BasicButton
                                              variant="contained"
                                              id={
                                                this.props?.objectType +
                                                "-" +
                                                this.props?.name
                                              }
                                              text={" "}
                                              icon={nodeItem.icon}
                                            />
                                          </div>
                                        </div>
                                      ) : (
                                        <></>
                                      )
                                  )
                                ) : (
                                  <> {(node === 'item_no' && this.props.config.primaryKey[0]==='po_line_id') ? parseInt(rowIndex+1) :dataItem[node]}</>
                                )}
                                {this.props?.config?.dataNodeGroups?.[node] ? (
                                  <>
                                    {this.props?.config?.dataNodeGroups?.[
                                      node
                                    ]?.map((item) =>
                                      dataItem["editing"] &&
                                      this.props?.config?.inlineEdits?.some(
                                        (e) => e.dataNode === item
                                      ) ? (
                                        <> </>
                                      ) : (
                                        <div>{dataItem[item]}</div>
                                      )
                                    )}
                                  </>
                                ) : (
                                  <> </>
                                )}

                                {dataItem["editing"] &&
                                this.props?.config?.inlineEdits?.some(
                                  (e) => e.dataNode === node
                                ) ? (
                                  <TableInlineEdits
                                    nodeName={node}
                                    placeholder={
                                      this.props?.config?.inlineEdits?.find(
                                        (e) => e.dataNode === node
                                      )?.placeholder
                                    }
                                    validateMethod={
                                      this.props?.config?.inlineEdits?.find(
                                        (e) => e.dataNode === node
                                      )?.validateMethod
                                    }
                                    inputType={this.state.dataTypeValues[node]}
                                    inputValue={
                                      this.state.listEdits?.[rowIndex]?.[node]
                                    }
                                    index={rowIndex}
                                    handleInlineEditChange={this.handleInlineEditChange.bind(
                                      this
                                    )}
                                    selectOptions={
                                      this.state.selectOptionValues?.[node]
                                    }
                                    searchString={this.props?.searchString}
                                    isMandatory={
                                      this.props?.config?.inlineEdits?.find(
                                        (e) => e.dataNode === node
                                      )?.isMandatory || false
                                    }
                                    showMandatory={
                                      this.props?.config?.inlineEdits?.find(
                                        (e) => e.dataNode === node
                                      )?.isMandatory || false
                                    }
                                  />
                                ) : (
                                  <> </>
                                )}

                                {this.props?.config?.dataNodeGroups?.[node] ? (
                                  <>
                                    {this.props?.config?.dataNodeGroups?.[
                                      node
                                    ]?.map((item) =>
                                      dataItem["editing"] &&
                                      this.props?.config?.inlineEdits?.some(
                                        (e) => e.dataNode === item
                                      ) ? (
                                        <TableInlineEdits
                                          nodeName={item}
                                          placeholder={
                                            this.props?.config?.inlineEdits?.find(
                                              (e) => e.dataNode === node
                                            )?.placeholder
                                          }
                                          validateMethod={
                                            this.props?.config?.inlineEdits?.find(
                                              (e) => e.dataNode === node
                                            )?.validateMethod
                                          }
                                          inputType={"text"}
                                          inputValue={
                                            this.state.listEdits?.[rowIndex]?.[
                                              item
                                            ]
                                          }
                                          index={rowIndex}
                                          handleInlineEditChange={this.handleInlineEditChange.bind(
                                            this
                                          )}
                                          searchString={
                                            this.props?.searchString
                                          }
                                        />
                                      ) : (
                                        <> </>
                                      )
                                    )}
                                  </>
                                ) : (
                                  <> </>
                                )}
                              </TableCell>
                            )
                          )}
                          {this.props?.config?.actions && (
                            <TableCell
                              key={this.props.className + "action"}
                              className={"border-ver-1-secondary"}
                            >
                              {" "}
                              <div className="row">
                                {
                                  // dataItem.add ?
                                  //     <div key={this.props.className}
                                  //         className="col-md padding-3">
                                  //         <MDBIcon
                                  //             icon={dataItem.add}
                                  //             className="action-icon"
                                  //         />
                                  //     </div> :
                                  this.props?.config?.actions?.map(
                                    (action, indexNo) => (
                                      <div
                                        key={
                                          this.props.className + "-" + indexNo
                                        }
                                        className="col-md padding-3"
                                        disabled={
                                          this.props?.tabValue === 1
                                            ? !dataItem?.is_edit
                                            : false
                                        }
                                        onMouseOver={(e) => {
                                          action === "info-circle"
                                            ? this.props?.handleOnHoverEnter(
                                                e,
                                                dataItem
                                              )
                                            : null;
                                        }}
                                        onMouseOut={(e) => {
                                          action === "info-circle"
                                            ? this.props?.handleOnHoverLeave(e)
                                            : null;
                                        }}
                                        onDoubleClick={(e) =>
                                          action === "file"
                                            ? this.props?.handleCommentBox(
                                                e,
                                                dataItem
                                              )
                                            : null
                                        }
                                        onClick={() =>
                                          action === "copy"? this.props?.handleInlineCopy(
                                                  dataItem
                                                )
                                         : action === "pen"
                                            ? dataItem["editing"]
                                              ? this.props?.saveCallback(
                                                  this.state.listEdits,
                                                  rowIndex,
                                                  dataItem[
                                                    this.props.config.primaryKey
                                                  ],
                                                  dataItem
                                                )
                                              : this.props?.editCallback(
                                                  dataItem[
                                                    this.props.config.primaryKey
                                                  ],
                                                  dataItem
                                                )
                                            : action === "plus"
                                            ? this.props?.handleAddCallback(
                                                dataItem[
                                                  this.props.config.primaryKey
                                                ]
                                              )
                                            : action === "trash-alt" ||
                                              action === "times"
                                            ? this.props?.handleDelCallBack(
                                                dataItem[
                                                  this.props.config.primaryKey
                                                ],
                                                dataItem
                                              )
                                            : action === "arrows-alt-v"
                                            ? this.props?.handlepopupCallback(
                                                dataItem[
                                                  this.props.config.primaryKey
                                                ],
                                                dataItem
                                              )
                                            : action === "times"
                                            ? this.props?.handleInlineDelete(
                                                dataItem[
                                                  this.props.config.primaryKey
                                                ]
                                              )
                                            : null
                                        }
                                      >
                                        {action === "copy" ? (<MDBIcon
                                          icon={"copy"}
                                        />):action === "view" ? (
                                          <a href="#">{action}</a>
                                        ) : action === "pen" &&
                                          dataItem["editing"] ? (
                                          <MDBIcon
                                            icon={"save"}
                                            color="primary"
                                            className="action-icon"
                                          />
                                        ) 
                                        :
                                        action === "pen" &&
                                          !dataItem["editing"] ? (
                                          <MDBIcon
                                            icon={"pen"}
                                            color="primary"
                                            className="action-icon"
                                          />
                                        ) 
                                        : action === "info-circle" ? (
                                          <Tooltip
                                            title={dataItem?.change_log?.map(
                                              (item) => {
                                                return (
                                                  <div>{item.change_log}</div>
                                                );
                                              }
                                            )}
                                            placement="left"
                                          >
                                            <Button className="table-info-button">
                                              <MDBIcon
                                                icon={action}
                                                className="action-icon"
                                              />
                                            </Button>
                                          </Tooltip>
                                        ) : action === "file" &&
                                          this.props?.showNotesTooltip ? (
                                          <Tooltip
                                            title={dataItem?.notes}
                                            placement="left"
                                          >
                                            <Button className="table-info-button">
                                              <MDBIcon
                                                icon={action}
                                                className="action-icon"
                                              />
                                            </Button>
                                          </Tooltip>
                                        ) 
                                        :(
                                          <MDBIcon
                                            icon={action}
                                            className="action-icon"
                                            onClick={() =>
                                              this.props?.handleRowFileSelect(
                                                dataItem
                                              )
                                            }
                                          />
                                        )}
                                      </div>
                                    )
                                  )
                                }
                              </div>
                            </TableCell>
                          )}
                        </TableRow>
                      ))}
                    {this.props?.list?.length <= 0 && (
                      <TableRow>
                        <TableCell
                          className="text-align-center"
                          colSpan={this.props?.config?.headings?.length}
                        >
                          <b className="table-no-results">
                            {this.props?.customNoResultsMessage?.length > 0
                              ? this.props?.customNoResultsMessage
                              : "No Matching Results"}
                          </b>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </div>
          {this.props?.addItemToList && !this.props.isLoading ? (
        <> 
              {this.props.config.primaryKey == 'fileMaker_po_line_id' &&
                <div className="inlineAdd borderBtm">
                  {this.props?.config?.showActionsItemBottom?.map((nodeItem, nodeIndex) => (
                    <div style={{ width: nodeItem?.width ?? "" }}>
                      <div className="f-75rem c-black bold">
                        {nodeItem?.dataNode === 'currency_exchange' ? 'Actual Cost : ' : ''}
                        {nodeItem?.dataNode === 'total_cost_value' ? this.props?.actualCost : ''}
                      </div>
                      <div className="f-75rem c-black bold">
                        {nodeItem?.dataNode === 'currency_exchange' ? 'Remaining Balance : ' : ''}
                        {nodeItem?.dataNode === 'total_cost_value' ? this.props?.remainingBalance() : ''}
                      </div>
                    </div>
                  ))}
                </div>
              }
        <div className="inlineAdd borderBtm" id="table-buttoncolor">
              {this.props?.config?.inlineEdits?.map((nodeItem, nodeIndex) => (
                <TableInlineEdits
                  nodeName={nodeItem?.dataNode}
                  placeholder={nodeItem?.placeholder}
                  validateMethod={nodeItem?.validateMethod}
                  inputType={this.state.dataTypeValues[nodeItem?.dataNode]}
                  inputValue={this.state?.newItem?.[nodeItem?.dataNode]}
                  width={nodeItem.width}
                  icon={nodeItem.icon}
                  index={nodeIndex}
                  handleInlineEditChange={this.onChangeNewItemHandler}
                  selectOptions={
                    this.state.selectOptionValues?.[nodeItem?.dataNode]
                  }
                  searchString={this.props?.searchString}
                  isMandatory={nodeItem.isMandatory || false}
                  showMandatory={
                    (nodeItem.isMandatory && this.props.showMandatory) || false
                  }
                />
              ))}
              <MDBIcon
                icon={"plus-circle"}
                onClick={this.handleAddNewItem}
                className="table-newItem-btn"
              />
              {this.props?.showClear && (
                <div className="clear-btn">
                  <BasicButton
                    variant="text"
                    text="Clear"
                    onClick={() => this.setState({ newItem: {} })}
                  />
                </div>
              )}
            </div>
             
              </>   
          ) : (
            ""
          )}
        </div>
      </>
    );
  }
}

export default TableComponent;