export const OperatorList = [
  { label: "Operator 1", value: 1 },
  { label: "Operator 2", value: 2 },
];
export const GroupByOption = [
  { label: "Show", value: "Show" },
  { label: "Season", value: "Season" },
  { label: "Production Company", value: "Production_Company" },
  { label: "Vendor", value: "Vendor" },
  { label: "Detail Number", value: "Detail_Number" },
  { label: "Set Unit", value: "Set_Unit" },
];
export const GroupByOptionForTenant2 = [
  { label: "Show", value: "Show" },
  { label: "Season", value: "Season" },
  { label: "Vendor", value: "Vendor" }
];
export const SortByOption = [
  { label: "Ascending", value: "asc" },
  { label: "Descending", value: "desc" },
];
export const CriteriaList = [
  { value: "=", label: "Equal To" },
  { value: ">", label: "Greater Than" },
  { value: ">=", label: "Greater Than or Equal To" },
  { value: "<", label: "Less Than" },
  { value: "<=", label: "Less Than or Equal To" },
  { value: "like", label: "Like" },
  { value: "<>", label: "Not Equal To" },
  { value: "not like", label: "Not Like" },
];
