import React from "react";
import { MDBRow, MDBCol } from "mdbreact";
import { withUserContext } from "../../contexts/UserContext";
import _ from "lodash";
import { MDBCard, MDBCardBody, MDBCardText } from "mdb-react-ui-kit";
import CircularProgress from "@material-ui/core/CircularProgress";
import FileMakerShowDetails from "../FileMakerShowDetails/FileMakerShowDetails";
import ShowDetails from "../ShowDetails/ShowDetails";
class ShowDetailsLandingPage extends React.Component {
  constructor(props) {
    super(props);
    this.dashboardPOSelectedId = null;
    this.state = {
      showDetails: null,
      
    };
  }

  componentDidMount() {
    let dashboardPO = this.props.location.state;
  }
  render() {
    if((this.props?.userContext?.active_tenant?.tenant_id || this.props?.userContext?.user_profile?.tenant_id) == 2){
      return <FileMakerShowDetails  location={this.props.location}/>
    }else{
      return <ShowDetails  location={this.props.location}/>
    }
  }
}

export default withUserContext(ShowDetailsLandingPage);
