import React from "react";
import TableComponent from "../../components/SharedComponents/Table";
import { userListConfig, tableData } from "./config";
import PostWatchService from "../../services/service";
import * as Constants from "../../constants/constants";
import _ from "lodash";
import { updateTableSortConfigObject } from "../../Common/TableHelper";
import "./CommitsDetail.scss";

export class CommitsDetail extends React.Component {
  constructor(props) {
    super(props);
    this.userListConfig = userListConfig;
    this.state = {
      commitDetails: null,
      isFetchingDetails: false,
      sortCount: 0,
      sortOrder: null,
      sortBy: null,
      sortByColumn: null,
    };
  }
  componentDidMount() {
    this.getCommitsDetails();
  }
  getCommitsDetails = (showId) => {
    this.setState({ isFetchingDetails: true });
    PostWatchService.getDataParams(
      Constants.postWatchServiceBaseUrl +
        "/getcommitsbydetail?episodeId=" +
        this.props?.selectedEpisode?.episode_id +
        "&showSeasonId=" +
        this.props?.show_season_id +
        "&sortBy=" +
        this.state.sortBy +
        "&sortOrder=" +
        this.state.sortOrder,
      "",
      this.props.userContext?.active_tenant?.tenant_id
    ).then(
      (response) => {
        this.setState({
          commitDetails: response.data,
          isFetchingDetails: false,
        });
      },
      (err) => {
        this.setState({
          isFetchingDetails: false,
        });
      }
    );
  };

  handleTableColumnClick = (column) => {
    if (column === this.state.sortByColumn) {
      this.setState({ sortCount: this.state.sortCount + 1 }, () => {
        this.handleTableSort(column);
      });
    } else
      this.setState({ sortCount: 0 }, () => {
        this.handleTableSort(column);
      });
  };

  handleTableSort = (colName) => {
    this.setState(
      {
        sortOrder: this.state.sortCount % 2 === 0 ? "asc" : "desc",
        config: updateTableSortConfigObject(
          this.userListConfig,
          this.state.sortOrder,
          this.state.sortByColumn,
          colName
        ),
      },
      () => {
        this.setState({
          sortByColumn: colName,
        });
        // this.sortBy = colName;
        // let filters = { ...this.state.filters };

        let headerNode = _.find(this.userListConfig.headings, {
          headingLabel: colName,
        });
        if (headerNode) {
          // this.sortBy = headerNode.dataNode;
          this.setState({ sortBy: headerNode.dataNode }, () => {
            this.getCommitsDetails();
          });
        } else {
          this.getCommitsDetails();
        }
      }
    );
  };
  render() {
    return (
      <div className="commit-details-container" id="commit-by-detail">
        <TableComponent
          list={this.state.commitDetails || []}
          config={userListConfig}
          tableMode={"light"}
          isLoading={this.state.isFetchingDetails || false}
          maxHeight={"450"}
          sortCallback={this.handleTableColumnClick}
        />
      </div>
    );
  }
}

export default CommitsDetail;
