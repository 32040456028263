import React from "react";
import { MDBRow, MDBCol, MDBIcon } from "mdbreact";
import _ from "lodash";
import ArrowBack from "@material-ui/icons/ArrowBack";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import {
  MDBCard,
  MDBCardBody,
  MDBCardText,
  MDBTooltip,
} from "mdb-react-ui-kit";
import { withUserContext } from "../../contexts/UserContext";
import SearchSelectField from "../../components/SharedComponents/SearchSelectField";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import BasicButton from "../../components/SharedComponents/BasicButton/BasicButton";
import SelectField from "../../components/SharedComponents/SelectField/SelectField";
import BasicTextArea from "../../components/SharedComponents/BasicTextArea/BasicTextArea";
import TableComponent from "../../components/SharedComponents/Table";
import "./PODetails.scss";
import {
  optionalRightsConfig,
  optionalRightsList,
  NewPOHeader,
  NewPOLine,
  revised,
} from "./config";
import PostWatchService from "../../services/service";
import * as Constants from "../../constants/constants";
import Popover from "@material-ui/core/Popover";
import axios from "axios";
import Modal from "../../components/SharedComponents/Modal/Modal";


let PONo;
class PODetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      poLinesList: null,
      isFetchingPOLines: false,
      showToolTip: false,
      tooltipElement: null,
      tooltipItem: null,
      poHeaders: null,
      NewPOHeader: { ...NewPOHeader },
      episodes: props.episodes,
      setUnits: null,
      isFetchingSetUnits: false,
      accountDetails: null,
      isFetchingAccountDetails: false,
      currencies: null,
      isFetchingCurrencies: false,
      isFetchingPoHeaders: false,
      searchCancelToken: null,
      selectedVendor: null,
      vendorsList: null,
      selectedVendorLocation: null,
      selectedVendorLocationList: null,
      totalCost: null,
      postInitiated: false,
      anchorEl: null,
      openPopover: false,
      selectedNoteRow: null,
      selectedNoteMessage: null,
      poRangeData: null,
      poErrormsg: false,
      activPORangeList: null,
      selectedTemplate: null,
      selectedTemplatePONumber: null,
      isFetchingShowReport: false,
      showOrientationModal: false,
      reportData: null,
      defaultCompanyExchangeRate:null,
      defaultCompanyCurrency:null
    };
  }

  componentDidMount() {
    let option = this.state.episodes.map((item) => {
      return { label: item.gl_number, value: item.gl_number };
    });
    optionalRightsConfig.headings[0].selectOptions = option;
    optionalRightsConfig.headings[9].selectOptions = revised;
    // -1 for New PO creation
    if (this.props?.poOrder === -1) {
      let poHeaderNew = { ...NewPOHeader };
      poHeaderNew.show_season_id = this.props?.selectedSeason?.show_season_id;
      poHeaderNew.episode_id = this.props?.selectedEpisode?.episode_id;
      poHeaderNew.po_prefix = this.props?.preFix;
      this.setState({ poHeaders: poHeaderNew });
    } else {
      this.getPOLines(this.props?.poOrder?.po_id);
      // If in PO is void then we dont have to show edit and delete icons
      if (this.props?.poOrder?.is_void === 1) {
        optionalRightsConfig.actions = ["", "file", "info-circle", "", ""];
      } else {
        optionalRightsConfig.actions = ["copy", "file", "info-circle", "pen", "times"];
      }
      let selectedVendorLocation = _.find(
        this.props?.poOrder?.vendor_locations,
        { vendor_location_id: this.props?.poOrder?.po_vendor_location_id }
      );
      this.setState({ poHeaders: { ...this.props?.poOrder } }, () => {
        this.setState({
          selectedVendor: {
            vendor_name: this.state.poHeaders?.vendor_name,
            vendor_id: this.state.poHeaders?.vendor_id,
            vendor_locations: this.state.poHeaders?.vendor_locations,
          },
          selectedVendorLocationList: this.state.poHeaders?.vendor_locations,
          selectedVendorLocation,
        });
      });
    }

    this.getSetUnits();
    this.getChartOfAccountDetails();   
    this.getMyVendors();
    this.props.userContext?.getPORange(
      this.props?.selectedSeason?.show_season_id,
      this.props?.userContext?.active_tenant?.tenant_id
    );
    this.props.userContext?.getTemplates(
      this.props.show_id,
      this.props.selectedSeason.season_id,
      this.props?.userContext?.user_profile?.user_id
    );
     //set default currency from prod company
     let defaultCompanyCurrencyItem = _.find(this.props.companies, {
      prod_company_id: this.props?.poOrder.prod_company_id,
    });
    this.setState({
      defaultCompanyExchangeRate:defaultCompanyCurrencyItem?.exchange_rate,
      defaultCompanyCurrency:defaultCompanyCurrencyItem?.currency_code+" ("+defaultCompanyCurrencyItem?.exchange_rate+")"
    })
  
  }
  getPOLines = (id) => {
    this.setState({ isFetchingPOLines: true });
    PostWatchService.getData(
      Constants.postWatchServiceBaseUrl + "/getpolineitems?poId=" + id,
      this.props?.userContext?.active_tenant?.tenant_id
    ).then(
      (response) => {
        this.setState(
          {
            poLinesList: response.data,
            isFetchingPOLines: false,
          },
          () => {
            this.calcaluteTotalCost();
            this.getCurrencies();
          }
        );
      },
      (err) => {
        this.setState({
          isFetchingPOLines: false,
        });
      }
    );
  };

  getSetUnits = () => {
    this.setState({ isFetchingSetUnits: true });
    let filters = {
      unit_code: null,
      description: null,
      page_count: null,
      page_no: null,
    };
    PostWatchService.getDataWithFilters(
      Constants.postWatchServiceBaseUrl + "/getsetunits",
      filters,
      this.props?.userContext?.active_tenant?.tenant_id
    )
      .then((res) => {
        let setUnitDeails = res.data?.records;
        let option = setUnitDeails.map((item) => {
          return {
            text: item.unit_code + " : " + item.description,
            value: item.setunit_id,
          };
        });
        optionalRightsConfig.headings[1].selectOptions = option;
        this.setState({
          setUnits: setUnitDeails,
          isFetchingSetUnits: false,
        });
      })
      .catch((err) => {
        console.log("err", err);
        this.setState({ isFetchingSetUnits: false });
      });
  };

  getChartOfAccountDetails = () => {
    this.setState({
      isFetchingAccountDetails: true,
    });
    let filters = {
      acc_code: null,
      description: null,
      page_count: null,
      page_no: null,
    };
    PostWatchService.getDataWithFilters(
      Constants.postWatchServiceBaseUrl + "/getchartofaccounts",
      filters,
      this.props?.userContext?.active_tenant?.tenant_id
    )
      .then((res) => {
        let accountDetails = res.data?.records;
        let option = accountDetails.map((item) => {
          return {
            text: item.acc_code + " : " + item.acc_desc,
            value: item.acc_id,
          };
        });
        optionalRightsConfig.headings[2].selectOptions = option;
        this.setState({
          isFetchingAccountDetails: false,
          accountDetails: res.data?.records,
        });
      })
      .catch((err) => {
        console.log("err", err);
        this.setState({ isFetchingAccountDetails: false });
      });
  };

  getCurrencies = () => {
    this.setState({
      isFetchingCurrencies: true,
    });
    PostWatchService.getDataParams(
      Constants.postWatchServiceBaseUrl +
      "/getshowcurrencies?showId=" +
      this.props.show_id,
      '',
      this.props?.userContext?.active_tenant?.tenant_id
    )
      .then((res) => {
        let currencyDetails = res.data;
        let option = currencyDetails.map((item) => {
          return {
            label: item.currency_exchange,
            value: item.currency_exchange,
          };
        });
        optionalRightsConfig.headings[7].selectOptions = option;
        this.setState({
          currencies: currencyDetails,
          isFetchingCurrencies: false,
        },()=>{
          this.state?.poLinesList ?  this.updateUnavailableCurrency() :
          setTimeout(this.updateUnavailableCurrency(),3000)
         
        });
      })
      .catch((err) => {
        console.log("err", err);
        this.setState({ isFetchingCurrencies: false, isSubmit: false });
      });
  };

  updateUnavailableCurrency(){
    this.state?.poLinesList?.map(poItem=>{
      const currencyNotFound = optionalRightsConfig?.headings[7]?.selectOptions?.find(currencyitem => currencyitem?.value == poItem?.currency_exchange);
      currencyNotFound == undefined && optionalRightsConfig.headings[7].selectOptions.push(
        { label: poItem?.currency_exchange, value: poItem?.currency_exchange, is_active: 0 })            
    });
  }

  getMyVendors = () => {
    PostWatchService.getDataParams(
      Constants.postWatchServiceBaseUrl +
      `/getuservendors?userId=${this.props?.userContext?.user_profile?.user_id}&showId=${this.props.location?.state?.show_id}&pageNo=null&pageCount=null`,'',
      this.props?.userContext?.active_tenant?.tenant_id
    ).then(
      (response) => {
        this.setState({
          vendorsList: response.data.records,
        });
      },
      (err) => { }
    );
  };

  getPORange = () => {
    PostWatchService.getData(
      Constants.postWatchServiceBaseUrl +
      "/getporangedetails?showSeasonId=" +
      this.props?.selectedSeason?.show_season_id,
      this.props?.userContext?.active_tenant?.tenant_id
    ).then(
      (response) => {
        this.setState(
          {
            poRangeData: response.data,
          },
          () => {
            this.setActivePORange();
          }
        );
      },
      (err) => { }
    );
  };
  setActivePORange = () => {
    // Need to show only Active / Availble PO numbers
    let activePORange = _.filter(this.state.poRangeData, { is_avail: "1" });
    if (activePORange) {
      this.setState({ activPORangeList: activePORange });
    }
  };

  calcaluteTotalCost = () => {
    let totalCost = this.state.poLinesList?.reduce((accumulator, item) => {
      return accumulator + item.total_cost;
    }, 0);
    totalCost = Number.parseFloat(totalCost).toFixed(2);
    totalCost = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(totalCost);

    this.setState({ totalCost });
  };

  saveInlineEdits = (data, index) => {
    if (this.validateBeforeSave(data[index])) {
      // Since these are searchtext they will return item with value and label from which we need to send only id
      // If dropdowns are not changed then take direct number, if dropdown are changed then we get objects so take ?.value
      data[index].setunit_id = data[index]?.setunit_id?.value
        ? data[index]?.setunit_id?.value
        : data[index]?.setunit_id;
      data[index].chartofaccount_id = data[index]?.chartofaccount_id?.value
        ? data[index]?.chartofaccount_id?.value
        : data[index]?.chartofaccount_id;
      // Service side require "adj_currency_id" and "adj_curr_exch_rate" for some calculation so appending these well
      let currencyItem = _.find(this.state.currencies, {
        currency_exchange: data[index].currency_exchange,
      });

      if (currencyItem) {
        data[index].adj_currency_id = currencyItem.currency_id;
        data[index].adj_curr_exch_rate = currencyItem.exchange_rate;
      }
      data[index].is_copy = Number("0");
      this.postData(data[index]);
    }
  };

  postData = (data) => {
    this.setState({ isFetchingPOLines: true });
    PostWatchService.postData(
      Constants.postWatchServiceBaseUrl +
      `/postpolineitem?userId=` +
      this.props?.userContext?.user_profile?.user_id,
      data,
      this.props?.userContext?.active_tenant?.tenant_id
    ).then(
      (response) => {
        if (!response.data.error) {
          this.setState({
            isFetchingPOLines: false,
            postInitiated: false,
          });
          this.getPOLines(this.state?.poHeaders?.po_id);
        } else {
          this.setState({ isFetchingPOLines: false });
        }
      },
      (err) => {
        this.setState({ isFetchingPOLines: false });
        console.log("Post polineitem error: " + err);
      }
    );
  };

  handleInlineEdits = (id, dataItem) => {
    let poLinesList = this.state.poLinesList;
    // Setunit and Acc are search select which require value and text to render in the table, so here we are building that object
    this.setState({
      poLinesList: poLinesList.map(
        (item) => {
          if (item.po_line_id === id) {
            item["editing"] = true;
            item["setunit_id"] = {
              value: item["setunit_id"],
              text: item["set_unit"],
            };
            item["chartofaccount_id"] = {
              value: item["chartofaccount_id"],
              text: item["acc_detail"],
            };
            if(!item?.currency_exchange){
            item["currency_exchange"] = this.state.defaultCompanyCurrency;
            item["total_cost"]= Number.parseFloat(item.subtotal * (this.state.defaultCompanyExchangeRate ?? 1)).toFixed(2);
            item["total_cost_value"]= Number.parseFloat(item.subtotal * (this.state.defaultCompanyExchangeRate ?? 1)).toFixed(2);
            }
          }
          return item;
        },
        () => {
          console.log("handleInlineEdit", this.state.poLinesList);
        }
      ),
    });
 
  };

  handleCancelInlineEdits = () => {
    let poLinesList = this.state.poLinesList;
    this.setState({
      poLinesList: poLinesList.map((item) => {
        item["editing"] = false;
        return item;
      }),
    });
  };

  handleSyncPopupClick = () => { };
  handleClickEPSModal = () => { };

  validateBeforeSave = (newDataItem) => {
    // Check for required fields
    let canSubmit =
      newDataItem?.gl_number &&
      newDataItem?.chartofaccount_id &&
      newDataItem?.unit_cost &&
      newDataItem?.quantity;

    return canSubmit;
  };

  handleAddNewItem = (newDataItem) => {
    this.setState({ postInitiated: true });
    if (this.validateBeforeSave(newDataItem)) {
      newDataItem.po_id = this.state?.poHeaders?.po_id;
      newDataItem.episode_id = this.props?.selectedEpisode?.episode_id;
      newDataItem.chartofaccount_id = newDataItem?.chartofaccount_id?.value;
      newDataItem.setunit_id = newDataItem?.setunit_id?.value;
      newDataItem.subtotal = newDataItem.quantity * newDataItem.unit_cost;

      let currency = _.find(this.state.currencies, {
        currency_id: newDataItem?.adj_currency_id,
      });

      if (currency?.exchange_rate) {
        newDataItem.total_cost = newDataItem.subtotal * currency.exchange_rate;
        newDataItem.total_cost = Number.parseFloat(
          newDataItem.total_cost
        ).toFixed(2);
      }
      // Service side require "adj_currency_id" and "adj_curr_exch_rate" for some calculation so appending these well
      let currencyItem = _.find(this.state.currencies, {
        currency_exchange: newDataItem.currency_exchange,
      });

      if (currencyItem) {
        newDataItem.adj_currency_id = currencyItem.currency_id;
        newDataItem.adj_curr_exch_rate = currencyItem.exchange_rate;
      }

      // Setting default value which is "N"
      newDataItem.is_revised = newDataItem.is_revised
        ? newDataItem.is_revised
        : "N";

      optionalRightsConfig.isClearFields = true;
      this.postData(newDataItem);
    } else {
      // This will not clear already entered data
      optionalRightsConfig.isClearFields = false;
    }
  };

  handleOnHoverEnter = (e, data) => {
    // e.stopPropagation();
    // this.setState({
    //   tooltipElement: e.currentTarget,
    //   tooltipItem: data,
    //   showToolTip: true,
    // });
  };
  handleOnHoverLeave = (e) => {
    // e.stopPropagation();
    // this.setState({
    //   tooltipElement: null,
    //   tooltipItem: null,
    //   showToolTip: false,
    // });
  };

  onPoHeaderChange = (field, value) => {
    this.setState({ poErrormsg: false });
    this.setState(
      (prevState) => ({
        poHeaders: {
          ...prevState.poHeaders,
          [field]: value,
        },
      }),
      () => {
        // if (field === "prod_company_id") {
        //   let poHeaders = { ...this.state.poHeaders };
        //   poHeaders.po_vendor_location_id = null;
        //   this.setState({ selectedVendor: null, poHeaders });
        // }
      }
    );
  };
  validatePOHeaders = (seasonData) => {
    let canSubmit = this.state.poHeaders?.po_number || false;
    return canSubmit;
  };

  savePoHeader = () => {
    if (this.validatePOHeaders()) {
      PONo = this.state.poHeaders?.po_number;
      this.setState({ isFetchingPoHeaders: true });
      PostWatchService.postData(
        Constants.postWatchServiceBaseUrl +
        `/postpoheader?userId=` +
        this.props?.userContext?.user_profile?.user_id,
        this.state.poHeaders,
        this.props?.userContext?.active_tenant?.tenant_id
      ).then(
        (response) => {
          if (response.data[0]?.po_id == 0) {
            this.props.userContext?.getPORange(
              this.props?.selectedSeason?.show_season_id,
              this.props?.userContext?.active_tenant?.tenant_id
            );
            this.setState({ poErrormsg: true, isFetchingPoHeaders: false });
          }
          else if (!response.data.error) {
            this.setState({
              isFetchingPoHeaders: false,
            });
            this.getPOHeaders(
              this.props?.selectedEpisode?.episode_id,
              response.data[0]?.po_id
            );
          } else {
            this.setState({ isFetchingPoHeaders: false });
          }
        },
        (err) => {
          this.setState({ isFetchingPoHeaders: false });
          console.log("Post poHeaders error: " + err);
        }
      );
    }
  };

  getPOHeaders = (id, poId) => {
    this.setState({ isFetchingPOHeaders: true });
    PostWatchService.getData(
      Constants.postWatchServiceBaseUrl +
      "/getpoheaders?episodeId=" +
      id +
      "&showSeasonId=" +
      this.props.selectedSeason?.show_season_id,
      this.props?.userContext?.active_tenant?.tenant_id
    ).then(
      (response) => {
        // For newly created PO po_id will be null so take if from response
        let POID = null;
        if (!this.state.poHeaders?.po_id) {
          POID = poId;
          // Get updated PO range label upon new PO created
          this.getPORangeLabel(this.props.selectedSeason?.show_season_id);
        } else {
          POID = this.state.poHeaders?.po_id;
        }
        let header = _.find(response.data, {
          po_id: POID,
        });
        if (header?.is_void) {
          optionalRightsConfig.actions = ["", "file", "info-circle", "", ""];
        } else {
          optionalRightsConfig.actions = [
            "copy",
            "file",
            "info-circle",
            "pen",
            "times",
          ];
        }
        this.setState({
          poHeaders: header || {},
          isFetchingPOHeaders: false,
        },()=>{
          let defaultCompanyCurrencyItem = _.find(this.props.companies, {
            prod_company_id: this.state?.poHeaders?.prod_company_id,
          });
          this.setState({
            defaultCompanyExchangeRate:defaultCompanyCurrencyItem?.exchange_rate,
            defaultCompanyCurrency:defaultCompanyCurrencyItem?.currency_code+" ("+defaultCompanyCurrencyItem?.exchange_rate+")"
          })
          this.getCurrencies();
        });
      },
      (err) => {
        this.setState({
          isFetchingPOHeaders: false,
        });
      }
    );
  };

  getPORangeLabel = (showSeasonId) => {
    PostWatchService.getData(
      Constants.postWatchServiceBaseUrl +
      "/getshowseasonporange?showSeasonId=" +
      showSeasonId,
      this.props?.userContext?.active_tenant?.tenant_id
    ).then(
      (response) => {
        this.props.userContext.setPoRangeLabel(response.data[0].po_range);
      },
      (err) => { }
    );
  };

  onVendorSelection = (item) => {
    let vendor = _.find(this.state.vendorsList, { vendor_id: item?.value });
    if (vendor) {
      vendor = {
        ...{ vendor_id: item.value, vendor_name: item.text },
        ...{ vendor_locations: vendor.vendor_locations },
      };
      this.setState(
        {
          selectedVendor: { ...vendor },
          selectedVendorLocation: vendor.vendor_locations[0],
          selectedVendorLocationList: vendor.vendor_locations,
        },
        () => { }
      );
      this.onPoHeaderChange("vendor_id", item.value);
      this.onPoHeaderChange("po_vendor_location_id", vendor.vendor_locations[0].vendor_location_id);
    } else {
      this.setState({ selectedVendor: null, selectedVendorLocation: null });
      this.onPoHeaderChange("po_vendor_location_id", null);
    }
  };

  searchVendors = (e) => {
    if (this.state.searchCancelToken != null)
      this.state.searchCancelToken.cancel(
        "Operation canceled due to new request"
      );
    var cancelToken = axios.CancelToken.source();
    this.setState({ searchCancelToken: cancelToken });
    if (e.target.value !== null) {
      this.setState({ isFetchingVendors: true });
      PostWatchService.getDataWithCancel(
        Constants.postWatchServiceBaseUrl +
        `/getvendorsbyname?vendorName=${e.target.value}&userId=${this.props?.userContext?.user_profile?.user_id}&showId=${this.props?.show_id}`,
        cancelToken,
        this.props?.userContext?.active_tenant?.tenant_id,
        1
      ).then(
        (response) => {
          this.setState({
            vendorsList: response.data,
            isFetchingVendors: false,
          });
        },
        (err) => {
          this.setState({
            isFetchingVendors: false,
          });
        }
      );
    }
  };

  vendorLocationChange = (id) => {
    let location = _.find(this.state?.selectedVendor?.vendor_locations, {
      vendor_location_id: id,
    });
    if (location) {
      this.setState({ selectedVendorLocation: location });
    }
    this.onPoHeaderChange("po_vendor_location_id", id);
  };
  handleDelCallBack = (id, dataItem) => {
    let data = dataItem;
    data.is_deleted = 1;
    this.postData(data);
  };

  handleCommentBox = (e, data) => {
    let poLine = _.find(this.state?.poLinesList, {
      po_line_id: data.po_line_id,
    });
    this.setState({
      anchorEl: e.currentTarget,
      openPopover: true,
      selectedNoteRow: poLine,
      selectedNoteMessage: data.notes || null,
    });
  };
  handleOnNoteChange = (value) => {
    this.setState({ selectedNoteMessage: value });
  };
  handleClose = () => {
    this.setState({ anchorEl: null, openPopover: false });
  };
  saveNotes = () => {
    let noteRow = JSON.parse(JSON.stringify(this.state.selectedNoteRow));
    noteRow.notes = this.state.selectedNoteMessage;
    noteRow.setunit_id = noteRow?.setunit_id?.value
      ? noteRow?.setunit_id?.value
      : noteRow?.setunit_id;
    noteRow.chartofaccount_id = noteRow?.chartofaccount_id?.value
      ? noteRow?.chartofaccount_id?.value
      : noteRow?.chartofaccount_id;
    this.postData(noteRow);
    this.handleClose();
  };

  onVoidClick = (value) => {
    let poHeaders = { ...this.state.poHeaders };
    poHeaders.is_void = value;
    /* If any row of the table is in edit mode, make it to normal mode.*/
    this.handleCancelInlineEdits();
    this.setState(
      { poHeaders, selectedTemplate: null, selectedTemplatePONumber: null },
      () => {
        this.savePoHeader();
      }
    );
  };

  handleTemplateChange = (field, value) => {
    if (field === "template") {
      let template = _.find(this.props?.userContext?.templateItems, {
        po_template_header_id: value,
      });
      if (template) {
        this.setState({ selectedTemplate: template });
      }
    }

    if (field === "ponumber") {
      if (value) {
        this.setState({ selectedTemplatePONumber: value });
      }
    }
  };
  loadTemplatePOLines = () => {
    if (this.state?.selectedTemplate && this.state?.selectedTemplatePONumber) {
      this.getTemplatePOLines();
    }
  };

  getTemplatePOLines = () => {
    this.setState({ isFetchingPOLines: true });
    PostWatchService.getData(
      Constants.postWatchServiceBaseUrl +
      "/getpotemplates?poTemplateHeaderId=" +
      this.state.selectedTemplate.po_template_header_id,
      this.props?.userContext?.active_tenant?.tenant_id
    ).then(
      (response) => {
        let poLinesList = JSON.parse(JSON.stringify(this.state.poLinesList));
        if (poLinesList === null) {
          poLinesList = [];
        }

        let templatePOLines = response.data;
        // Add these two propeties since they dont come with template po lines
        _.forEach(templatePOLines, (item) => {
          item.po_line_id = null;
          item.po_id = this.state.poHeaders?.po_id;
          item.episode_id = this.props?.selectedEpisode?.episode_id;
          // Add GL Number only if template po line does not have GL number
          if (!item.gl_number) {
            item.gl_number = this.state?.selectedTemplatePONumber;
          }
        });
        poLinesList = [...poLinesList, ...templatePOLines];
        this.postAllPOlineItems(poLinesList);
      },
      (err) => {
        this.setState({
          isFetchingPOLines: false,
        });
      }
    );
  };
  postAllPOlineItems = (data) => {
    this.setState({ isFetchingPOLines: true });
    PostWatchService.postData(
      Constants.postWatchServiceBaseUrl +
      `/postallpolineitems?userId=` +
      this.props?.userContext?.user_profile?.user_id,
      data,
      this.props?.userContext?.active_tenant?.tenant_id
    ).then(
      (response) => {
        if (!response.data.error) {
          this.setState({
            isFetchingPOLines: false,
            postInitiated: false,
          });
          this.getPOLines(this.state?.poHeaders?.po_id);
        } else {
          this.setState({ isFetchingPOLines: false });
        }
      },
      (err) => {
        this.setState({ isFetchingPOLines: false });
        console.log("Post postallpolineitems error: " + err);
      }
    );
  };

  toggleOrientationModalVisibality = () => {
    this.setState({
      showOrientationModal: !this.state.showOrientationModal,
      reportData: this.state.poHeaders.po_id,
    });
  };

  handleInlineCopy = (copyRecord) => {
    if (this.validateBeforeSave(copyRecord)) {
      // Since these are searchtext they will return item with value and label from which we need to send only id
      // If dropdowns are not changed then take direct number, if dropdown are changed then we get objects so take ?.value
      copyRecord.setunit_id = copyRecord?.setunit_id?.value
        ? copyRecord?.setunit_id?.value
        : copyRecord?.setunit_id;
      copyRecord.chartofaccount_id = copyRecord?.chartofaccount_id?.value
        ? copyRecord?.chartofaccount_id?.value
        : copyRecord?.chartofaccount_id;
      // Service side require "adj_currency_id" and "adj_curr_exch_rate" for some calculation so appending these well
      let currencyItem = _.find(this.state.currencies, {
        currency_exchange: copyRecord.currency_exchange,
      });

      if (currencyItem) {
        copyRecord.adj_currency_id = currencyItem.currency_id;
        copyRecord.adj_curr_exch_rate = currencyItem.exchange_rate;
      }
      copyRecord.is_copy = Number("1");
      this.postData(copyRecord);
    }
  }

  render() {
    let showToolTip = Boolean(this.state.showToolTip);
    let tableLoading =
      this.state.isFetchingSetUnits ||
      this.state.isFetchingAccountDetails ||
      this.state.isFetchingCurrencies;
    return (
      <>
        <MDBRow className="mb-2">
          <MDBCol md={6}>
            <div
              className="f-85rem c-black cursor-pointer"
              onClick={this.props.backToPO}
            >
              <ArrowBack className="me-2" />
              Back to Purchase Order Grid
            </div>
          </MDBCol>
          <MDBCol
            md={6}
            className="d-flex justify-content-end align-items-center"
          >
            <BasicButton
              className="me-3"
              text={"PDF"}
              icon={"file"}
              onClick={this.toggleOrientationModalVisibality}
            />
            <BasicButton
              text={
                this.state.isFetchingPoHeaders ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                    "Save"
                  )
              }
              variant="contained"
              type="inline"
              icon={"save"}
              onClick={this.savePoHeader}
            />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol md={12}>
            <MDBCard className="">
              <MDBCardBody>
                <MDBCardText>
                  <MDBRow>
                    <MDBCol md={4}>
                      <MDBRow>
                        <MDBCol md={5} className="">
                          {/* <div className="f-75rem c-black">{""}</div>
                          <div className="f-75rem c-black">{""}</div> */}
                          <div className="f-75rem c-black">PO No.</div>
                          <span className={`f-65rem c-black ${!this.state.poHeaders?.po_id ? 'move-up' : ''}`}>
                            {this.props?.preFix}
                          </span>
                          {!this.state.poHeaders?.po_id ? (
                            <SelectField
                              className="sh-select"
                              value={this.state.poHeaders?.po_number || null}
                              disabled={this.state.poHeaders?.is_void}
                              options={this.props?.userContext?.poRangeActiveItems?.map(
                                (item) => {
                                  return {
                                    value: item.po_number,
                                    label: item.po_number,
                                  };
                                }
                              )}
                              placeHolderText="- Select -"
                              onChange={(e, value) =>
                                this.onPoHeaderChange(
                                  "po_number",
                                  e.target.value
                                )
                              }
                              showMandatory={true}
                              isMandatory={true}
                            />
                          ) : (
                              <span className="f-65rem c-black">
                                &nbsp;{this.state.poHeaders?.po_number}
                              </span>
                            )}
                          {this.state.poErrormsg && <p className="errorText">PO No.{PONo} Already Used</p>}

                          {this.state.poHeaders?.is_void ? (
                            <div className="f-75rem redStar mt-4">VOIDED</div>
                          ) : (
                              ""
                            )}
                        </MDBCol>
                        <MDBCol md={7} className="ps-0">
                          <div className="f-75rem c-black">Company</div>
                          <SelectField
                            value={
                              this.state.poHeaders?.prod_company_id || null
                            }
                            placeholder={"-Select-"}
                            disabled={this.state.poHeaders?.is_void}
                            options={this.props.companies?.map((item) => {
                              return {
                                value: item.prod_company_id,
                                label: item.comp_name,
                              };
                            })}
                            onChange={(e, value) =>
                              this.onPoHeaderChange(
                                "prod_company_id",
                                e.target.value
                              )
                            }
                          />
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>
                    <MDBCol md={3}>
                      <div className="f-75rem c-black">Vendor</div>
                      <SearchSelectField
                        detail_selected={
                          this.state?.selectedVendor
                            ? {
                              value: this.state?.selectedVendor?.vendor_id,
                              text: this.state?.selectedVendor?.vendor_name,
                            }
                            : null
                        }
                        options={this.state.vendorsList?.map((item) => ({
                          text: item.vendor_name,
                          value: item.vendor_id,
                          is_active: item.is_active,
                          is_favourite: item.is_favourite,
                        }))}
                        loading={this.state.isFetchingVendors}
                        width="100%"
                        disabled={this.state.poHeaders?.is_void}
                        multiple={false}
                        searchSelect={true}
                        searchText={this.searchVendors}
                        placeholder="- Type to search vendors -"
                        noOptionsText="No results"
                        valueSelected={(e, newValue) => {
                          this.onVendorSelection(newValue);
                        }}
                      />
                      {this.state?.selectedVendor && (
                        <>
                          <SelectField
                            value={this.state.poHeaders?.po_vendor_location_id}
                            options={this.state?.selectedVendorLocationList?.map(
                              (item, index) => {
                                return {
                                  value: item.vendor_location_id,
                                  label: item.location_name ? item.location_name : `Location ${index}`,
                                };
                              }
                            )}
                            onChange={(e, value) =>
                              this.vendorLocationChange(e.target.value)
                            }
                            disabled={this.state.poHeaders?.is_void}
                            placeHolderText="Select Location"
                          />
                          {this.state.selectedVendorLocation
                            ?.location_address && (
                              <div className="f-75rem c-black">
                                {
                                  this.state.selectedVendorLocation
                                    ?.location_address
                                }
                              </div>
                            )}
                        </>
                      )}
                    </MDBCol>

                    <MDBCol md={3}>
                      <div className="f-75rem c-black">Notes (internal)</div>
                      <BasicTextArea
                        value={this.state.poHeaders?.notes}
                        onChange={(e, value) =>this.onPoHeaderChange("notes", e.target.value)}
                        rows={2}
                        // disabled={this.state.poHeaders?.is_void}
                        id={"message"}
                        limit={"2000"}
                      />
                    </MDBCol>

                    <MDBCol md={2}>
                      <div className="f-75rem c-black">Created On</div>
                      <div className="f-65rem">
                        {this.state.poHeaders?.created_at_text}
                      </div>
                      <div className="f-75rem c-black">Created By</div>
                      <div className="f-65rem">
                        {this.state.poHeaders?.full_name}
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    {this.state.poHeaders?.po_id && (
                      <>
                        <MDBCol md={3} className="mb-2">
                          <div className="f-75rem c-black">
                            Add from Template
                          </div>
                          <SelectField
                            placeHolderText={"- Select Template -"}
                            value={
                              this.state.selectedTemplate?.po_template_header_id
                            }
                            disabled={this.state.poHeaders?.is_void}
                            options={
                              this.props.userContext?.templateItems?.map(
                                (item) => ({
                                  label: item?.po_template_name,
                                  value: item?.po_template_header_id,
                                })
                              ) || []
                            }
                            onChange={(e, value) =>
                              this.handleTemplateChange(
                                "template",
                                e.target.value
                              )
                            }
                          />
                        </MDBCol>
                        <MDBCol md={2} className="mb-2">
                          <div className="f-75rem c-black">
                            GL No. {"  "}
                            <Tooltip
                              title={
                                "Selected GL # will be added to all PO lines which does not have GL #'s"
                              }
                              placement="right"
                            >
                              <Button className="table-info-button">
                                <MDBIcon
                                  icon={"info-circle"}
                                  className="action-icon"
                                />
                              </Button>
                            </Tooltip>
                          </div>
                          <SelectField
                            className=""
                            value={this.state.selectedTemplatePONumber}
                            disabled={this.state.poHeaders?.is_void}
                            options={optionalRightsConfig.headings[0].selectOptions?.map(
                              (item) => {
                                return {
                                  value: item.value,
                                  label: item.label,
                                };
                              }
                            )}
                            placeHolderText="- Select GL# -"
                            onChange={(e, value) =>
                              this.handleTemplateChange(
                                "ponumber",
                                e.target.value
                              )
                            }
                          />
                        </MDBCol>

                        <MDBCol md={2} className="mb-2">
                          <div className="f-75rem c-black">&nbsp;</div>
                          <BasicButton
                            variant="outlined"
                            disabled={
                              !this.state?.selectedTemplate ||
                              !this.state?.selectedTemplatePONumber
                            }
                            text="Add"
                            onClick={() => this.loadTemplatePOLines()}
                          />
                        </MDBCol>
                      </>
                    )}
                    {!this.state.poHeaders?.po_id && (<MDBCol md={7}></MDBCol>)}
                    <MDBCol md={3}>
                      <div className="f-75rem c-black">Notes External to PDF</div>
                      <BasicTextArea
                        value={this.state.poHeaders?.exNotes}
                        onChange={(e, value) => this.onPoHeaderChange("exNotes", e.target.value)}
                        rows={2}
                        id={"message"}
                        limit={"2000"}
                      />
                    </MDBCol>
                  </MDBRow>
                  <MDBRow
                    className="mt-3 podetailtable"
                    id="podetailtable"
                  >
                    {this.state.poHeaders?.po_id && (
                      <MDBCol md={12}>
                        {tableLoading ? (
                          <div className="ContentLoader">
                            <CircularProgress />
                          </div>
                        ) : (
                            <>
                              <TableComponent
                                list={this.state?.poLinesList || []}
                                config={optionalRightsConfig}
                                isLoading={this.state.isFetchingPOLines}
                                tableMode={"light"}
                                customNoResultsMessage={"Add a line item"}
                                editCallback={this.handleInlineEdits.bind(this)}
                                saveCallback={this.saveInlineEdits.bind(this)}
                                handleAddNewItem={this.handleAddNewItem.bind(
                                  this
                                )}
                                addItemToList={!this.state.poHeaders?.is_void}
                                handleOnHoverEnter={this.handleOnHoverEnter}
                                handleOnHoverLeave={this.handleOnHoverLeave}
                                handleDelCallBack={this.handleDelCallBack}
                                showMandatory={this.state.postInitiated}
                                handleCommentBox={this.handleCommentBox}
                                currencies={this.state.currencies}
                                showNotesTooltip={true}
                                autoCalculate={true}
                                handleInlineCopy={this.handleInlineCopy}
                                defaultCompanyExchangeRate= {this.state.defaultCompanyExchangeRate}
                                defaultCompanyCurrency={this.state.defaultCompanyCurrency}
                              />

                              <div class="float-left">
                                {this.state.poHeaders?.is_void ? (
                                  <BasicButton
                                    className="mt-2 cancel-void"
                                    variant="outlined"
                                    text="Cancel Void"
                                    onClick={() => this.onVoidClick(0)}
                                  />
                                ) : (
                                    <BasicButton
                                      className="void-btn"
                                      variant="outlined"
                                      text="Void"
                                      onClick={() => this.onVoidClick(1)}
                                    />
                                  )}
                              </div>
                              <div class="po-table-total-container f-75rem c-black">
                                {this.state.totalCost !== null && (
                                  <>
                                    <div>Total Amount</div>
                                    <div>{this.state.totalCost}</div>
                                  </>
                                )}
                              </div>
                              <MDBRow>
                                <MDBCol md={12}>
                                  {!this.state.poHeaders?.is_void && (
                                    <Popover
                                      anchorOrigin={{
                                        vertical: "top",
                                        horizontal: "left",
                                      }}
                                      transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                      }}
                                      id={"popover"}
                                      open={this.state.openPopover}
                                      anchorEl={this.state.anchorEl}
                                      onClose={this.handleClose}
                                    >
                                      <MDBRow>
                                        <MDBCol md={12}>
                                          <BasicTextArea
                                            width="300px"
                                            className="polines-notes"
                                            rows={3}
                                            value={
                                              this.state.selectedNoteMessage || ""
                                            }
                                            onChange={(e) =>
                                              this.handleOnNoteChange(
                                                e.target.value
                                              )
                                            }
                                          />
                                        </MDBCol>
                                      </MDBRow>
                                      <MDBRow>
                                        <MDBCol
                                          md={12}
                                          className="d-flex justify-content-end"
                                        >
                                          <BasicButton
                                            text={"Save"}
                                            className="m-1"
                                            onClick={() => this.saveNotes()}
                                          />
                                          <BasicButton
                                            variant="outlined"
                                            type="inline"
                                            className="m-1"
                                            text={"Close"}
                                            onClick={() => this.handleClose()}
                                          />
                                        </MDBCol>
                                      </MDBRow>
                                    </Popover>
                                  )}
                                </MDBCol>
                              </MDBRow>
                            </>
                          )}
                      </MDBCol>
                    )}
                  </MDBRow>
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
        {this.state.showOrientationModal && (
          <Modal
            open={this.state.showOrientationModal || false}
            modalName={"Orientation"}
            comfirmModalTitle={"Orientation"}
            onClose={this.toggleOrientationModalVisibality}
            po_id={this.state.reportData}
            url="/getexportpodetails?reportName=po_report_"
            {...this.props}
          />
        )}
      </>
    );
  }
}

export default withUserContext(PODetails);