export const currenciesConfig = {
  headings: [
    {
      headingLabel: "Inactive",
      icon: "",
      width: "10%",
      inputType: "checkbox",
    },
    {
      headingLabel: "Description",
      icon: "",
      inputType: "text",
      width: "80%",
    },
  ],
  dataNodes: ["is_active", "lookup_value"],
  primaryKey: "lookup_data_id",
  actions: ["pen", "times"],
  nodeFields: [
    {
      column: "is_active",
      icon: "check",
    },
  ],
  buttonFields: [
    {
      column: "",
      icon: "pen",
    },
  ],
  inlineEdits: [
    {
      dataNode: "is_active",
      width: "9%",
    },    
    {
      dataNode: "lookup_value",
      width: "65%",
      isMandatory: true,
    },
  ],
};
export const currencyList = [
  {
    inactive: " ",
    code: "1000",
    description: "[Description]",
    id: 1,
  },
  {
    inactive: " ",
    code: "1000",
    description: "[Description]",
    id: 2,
  },
  {
    inactive: " ",
    code: "1000",
    description: "[Description]",
    id: 3,
  },
  {
    inactive: "yes",
    code: "1000",
    description: "[Description]",
    id: 4,
  },
  {
    inactive: " ",
    code: "1000",
    description: "[Description]",
    id: 5,
  },
  {
    inactive: " ",
    code: "1000",
    description: "[Description]",
    id: 6,
  },
  {
    inactive: " ",
    code: "1000",
    description: "[Description]",
    id: 7,
  },
  {
    inactive: " ",
    code: "1000",
    description: "[Description]",
    id: 8,
  },
];
