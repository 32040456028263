export const contactList = [
  { label: "c1", value: 1 },
  { label: "c2", value: 2 },
];
export const locationList = [
  { label: "location 1", value: 1 },
  { label: "location 2", value: 2 },
];
export const GroupByOption = [
  { label: "PO Number", value: 1 },
  { label: "Operator ", value: 2 },
];
export const newContact = {
  contact_name: "New Contact",
  vendor_contact_id: 9999,
  contact_email: null,
  contact_notes: null,
};
export const newLocation = {
  location_name: "New Location",
  vendor_location_id: 9999,
  location_address: null,
  location_notes: null,
};
export const newVendor = {
  vendor_id: null,
  vendor_name: null,
  tax_id: null,
  ap_number: null,
  notes: null,
  phone: null,
  is_active: 0,
  is_active_text: null,
  created_by: null,
  updated_by: null,
  created_at: null,
  vendor_contacts: [],
  vendor_locations: [],
};

export const accurate = [
  { label: "Yes", value: 1 },
  { label: "No", value: 0 },
];