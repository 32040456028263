import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { withOktaAuth } from "@okta/okta-react";
import LandingPage from "../home/LandingPage/LandingPage";
import UserManagement from "../../src/UserManagement/UserManagement";
import AdminSetupDashboard from "../../src/home/AdminSetup/AdminSetupDashboard/AdminSetupDashboard";
import ShowDetails from "../../src/components/ShowDetails/ShowDetails";
import FileMakerShowDetails from "../../src/components/FileMakerShowDetails/FileMakerShowDetails";
import DashboardPage from "../home/Dashboard/DashboardPage";
import { createBrowserHistory } from "history";
import ShowDetailsLandingPage from "../../src/components/FileMakerShowDetails/showDetailsLandingPage";

const history = createBrowserHistory();

export default withOktaAuth(
  class Routes extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
      return (
        <Switch history={history}>
          <Route
            exact={true}
            path="/"
            render={(props) => <DashboardPage {...props} />}
          />
          <Route
            exact={true}
            path="/usermanagement"
            render={(props) => <UserManagement {...props} />}
          />
          <Route
            exact={true}
            path="/admin"
            render={(props) => <AdminSetupDashboard {...props} />}
          />
          {/* <Route
            exact={true}
            path="/showdetails"
            render={(props) => <ShowDetails {...props} />}
          />
          <Route
            exact={true}
            path="/filemakershowdetails"
            render={(props) => <FileMakerShowDetails {...props} />}
          /> */}
          <Route
            exact={true}
            path="/showdetails"
            render={(props) => <ShowDetailsLandingPage {...props} />}
          />
          
        </Switch>
      );
    }
  }
);
