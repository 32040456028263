export const AdminSetupScreenList = [
  { label: "Chart of Account", value: 1 },
  { label: "Production Companies", value: 2 },
  { label: "Set Units", value: 3 },
  { label: "Vendors", value: 4 },
  { label: "Shows", value: 5 },
  { label: "Currencies", value: 6 },
  { label: "Seasons", value: 7 }
];

export const AdminSetupHboScreenList = [
  { label: "Vendors", value: 4 },
  { label: "Shows", value: 5 },
  { label: "Currencies", value: 6 },
  { label: "Seasons", value: 7 },
  { label: "Expenditure", value: 9 },
  { label: "Organization", value: 10 },
  // { label: "Task", value: 11 },
  { label: "Format", value: 12 },
  { label: "Language", value: 13 }
];

export const VendorAdminSetupHboScreenList = [
  { label: "Vendors", value: 4 }
];

export const adminMenuNonHBOTab= [
  { tab_id: 1, tab_name: "Chart of Accounts" },
  { tab_id: 2, tab_name: "Production Companies" },
  { tab_id: 3, tab_name: "Set Units" },
  { tab_id: 4, tab_name: "Vendors" },
  { tab_id: 5, tab_name: "Shows" },
  { tab_id: 6, tab_name: "Currencies" },
  { tab_id: 7, tab_name: "Seasons" }
]
export const adminMenuHBOTab= [       
  { tab_id: 4, tab_name: "Vendors" },
  { tab_id: 5, tab_name: "Shows" },
  { tab_id: 6, tab_name: "Currencies" },
  { tab_id: 7, tab_name: "Seasons" },
  { tab_id: 9, tab_name: "Expenditure" },
  { tab_id: 10, tab_name: "Organization" },
  // { tab_id: 11, tab_name: "Task" },
  { tab_id: 12, tab_name: "Format" },
  { tab_id: 13, tab_name: "Language" }
];
export const vendorAdminMenuHBOTab= [       
  { tab_id: 4, tab_name: "Vendors" }
];