import React from "react";
import { MDBRow, MDBCol, MDBContainer } from "mdbreact";
import { withUserContext } from "../../../contexts/UserContext";
import _ from "lodash";
import CircularProgress from "@material-ui/core/CircularProgress";
import PostWatchService from "../../../services/service";
import * as Constants from "../../../constants/constants";
import FormControl from "@material-ui/core/FormControl";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import BasicButton from "../../../components/SharedComponents/BasicButton/BasicButton";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import "./Orientation.scss";

class OrientationModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      poHeaders: null,
      isFetchingPOHeaders: false,
      isFetchingReport: false,
      orientation: "p",
    };
  }

  downloadUrl = (url, reportName) => {
    window.open(url, "_blank");
  };

  arrayCheckUncheck = (value, dataItem) => {
    let poHeaders = [...this.state.poHeaders];
    let index = _.findIndex(this.state.poHeaders, { po_id: dataItem.po_id });
    if (index != -1) {
      poHeaders[index].check = value;
    }
    this.setState({ poHeaders });
  };

  handleOrientationChange = (value) => {
    this.setState({
      orientation: value,
    });
  };

  downloadShowReport = () => {
    this.setState({ isFetchingShowReport: true });
    let orientation = this.state.orientation;
    PostWatchService.getDataParams(
      Constants.postWatchServiceBaseUrl +
        `${this.props.url}${orientation}&userId=` +
        this.props?.userContext?.user_profile?.user_id +
        "&poId=" +
        this.props.po_id,
      "",
      this.props.userContext?.active_tenant?.tenant_id
    ).then(
      (response) => {
        this.downloadUrl(response.data, "Show");
        this.setState({
          isFetchingShowReport: false,
        });
      },
      (err) => {
        this.setState({
          isFetchingShowReport: false,
        });
      }
    );
  };

  render() {
    let isLoading = this.state.isFetchingPOHeaders;
    return (
      <div className="orientation-container">
        {isLoading ? (
          <div className="ContentLoader">
            <CircularProgress />
          </div>
        ) : (
          <MDBContainer className="orientationContainer">
            <MDBRow className="btn-row">
              <MDBCol md={8} className="orientation-radio-btn">
                <FormControl className="radio-btn">
                  <RadioGroup
                    row
                    value={this.state?.orientation}
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    onChange={(e) =>
                      this.handleOrientationChange(e.target.value)
                    }
                  >
                    <MDBCol md={6}>
                      <FormControlLabel
                        value={"p"}
                        control={<Radio />}
                        label="Portrait"
                      />
                    </MDBCol>
                    <MDBCol md={6}>
                      <FormControlLabel
                        value={"l"}
                        control={<Radio />}
                        label="Landscape"
                      />
                    </MDBCol>
                  </RadioGroup>
                </FormControl>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md={4}></MDBCol>

              <MDBCol md={4} className="btn-orientations">
                <BasicButton
                  variant="contained"
                  type="inline"
                  text={
                    this.state.isFetchingShowReport ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : (
                      "Generate"
                    )
                  }
                  onClick={this.downloadShowReport}
                />
              </MDBCol>
              <MDBCol md={4} className="btn-orientations">
                <BasicButton
                  type="inline"
                  variant="outlined"
                  text={"Cancel"}
                  onClick={this.props.onClose}
                  disabled={false}
                />
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        )}
      </div>
    );
  }
}

export default withUserContext(OrientationModal);
