import React from "react";
import {
  MDBModal,
  MDBModalBody,
  MDBModalDialog,
  MDBModalContent,
  MDBContainer,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import "./Modal.scss";
//import { withStyles } from '@material-ui/core/styles';
import CancelIcon from "@material-ui/icons/Cancel";
import MessageModal from "../../SharedComponents/MessageModal";
//import { useStyles } from './Config';
import { withUserContext } from "../../../contexts/UserContext";
import ContactList from "../../ContactList/ContactList";
import NewUser from "../../NewUser/NewUser";
import ManageLookup from "../../ManageLookup/ManageLookup";
import MessageHistory from "../../../../src/home/HistoryMessage/HistoryMessage";
import * as appConstants from "../../../constants/appConstants";
import { AppService } from "../../../services/AppService";
import CreatePO from "../../CreatePO/CreatePO";
import PoTemplate from "../../PoTemplate/PoTemplate";
import Reports from "../../Reports/Reports";
import ShowsModal from "../../ShowsModal/ShowsModal";
import VendorModal from "../../VendorModal/VendorModal";
import ImportGl from "../ImportGL/ImportGl";
import ExportPO from "../../ExportPO/ExportPO";
import EditPORange from "../../EditPORange/EditPORange";
import OrientationModal from "../../ShowDetails/Orientaion/Orientation";

class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMessageModalOpen: false,
      haveUnsavedChanges: false,
      isPostingSuccess: false,
      isPostingFailed: false,
    };
  }

  componentDidMount() {
    this.messageModal$ = AppService.getMessageModalState().subscribe((data) => {
      this.handleMessageModalStatus(data);
    });
  }

  handleMessageModalStatus = (field, value) => {
    if (field === "unsaved") {
      this.setState({ haveUnsavedChanges: value });
    } else if (field === "success") {
      this.setState({
        isPostingSuccess: true,
        isPostingFailed: false,
        haveUnsavedChanges: false,
        isMessageModalOpen: true,
      });
    } else if (field === "fail") {
      this.setState({
        isPostingSuccess: false,
        isPostingFailed: true,
        haveUnsavedChanges: false,
        isMessageModalOpen: true,
      });
    }
  };

  onModalClose = () => {
    if (this.state.haveUnsavedChanges) {
      this.setState({ isMessageModalOpen: true });
    } else {
      this.props.onClose();
    }
  };

  getWidthForModal = () => {
    switch (this.props?.modalName) {
      case "NewSet":
        return "newSetWidth";
      case "RevampSet":
        return "revampSetModalWidth";
      case "AddCadFiles":
        return "addCadFilesModalWidth";
      case "AddCatalogFile":
        return "addCatalogFileModalWidth";
      case "EmailCatalog":
        return "EmailCatalogModalWidth";
      case "PrintCatalog":
        return "PrintCatalogModalWidth";
      case "ExportCatalog":
        return "exportCatalogModalWidth";
      case "NewUser":
        return "newUserModalWidth";
      case "EditPORange":
        return "editPORangeModalWidth";
      case "ManageLookup":
        return "manageLookupModalWidth";
      case "CreatePO":
        return "createPOModalWidth";
      case "PoTemplate":
        return "poTemplateModalWidth";
      case "History":
        return "historyModalWidth";
      case "Reports":
        return "reportsModalWidth";
      case "Shows":
        return "showsModalWidth";
      case "Vendor":
        return "vendorModalWidth";
      case "ImportGL":
        return "importGLModalWidth";
      case "ExportPO":
        return "exportPoModalWidth";
      case "Orientation":
        return "exportOrientationModalWidth";
      default:
        return "";
    }
  };

  onModalConfirm = () => {
    this.setState({ isMessageModalOpen: false });
    this.props.onClose();
  };

  getModalContent = () => {
    switch (this.props?.modalName) {
      case "ContactList":
        return (
          <ContactList
            handleMessageModalStatus={this.handleMessageModalStatus}
            callback={this.props.callback}
            onModalClose={this.onModalClose}
            onClose={this.props?.onClose}
            comfirmModalTitle={this.props.comfirmModalTitle}
            mode={this.props?.mode}
            contactDetails={this.props?.userData}
            statesList={this.props?.statesList}
            phoneTypeList={this.props?.phoneTypeList}
            onSave={this.props?.onSave}
            {...this.props}
          />
        );
      case "ManageLookup":
        return (
          <ManageLookup
            onClose={this.props?.onClose}
            primaryButtonText={"Save"}
            secondaryButtonText={"Cancel"}
            handleMessageModalStatus={this.handleMessageModalStatus}
            onModalClose={this.onModalClose}
          />
        );
      case "NewUser":
        return (
          <NewUser
            handleMessageModalStatus={this.handleMessageModalStatus}
            callback={this.props.callback}
            callbackRole={this.props.callbackRole}
            onModalClose={this.onModalClose}
            onClose={this.props?.onClose}
            comfirmModalTitle={this.props.comfirmModalTitle}
            mode={this.props?.mode}
            superUser={this.props.superUser}
            isUserProvisioner={this.props.isUserProvisioner}
            {...this.props}
          />
        );
      case "CreatePO":
        return (
          <CreatePO
            handleMessageModalStatus={this.handleMessageModalStatus}
            callback={this.props.callback}
            onModalClose={this.onModalClose}
            onClose={this.props?.onClose}
            comfirmModalTitle={this.props.comfirmModalTitle}
            mode={this.props?.mode}
            userData={this.props?.userData}
            allData={this.props?.allData}
            {...this.props}
          />
        );
      case "History":
        return (
          <MessageHistory
            callLogId={this.props?.callLogId}
            onClose={this.props?.onClose}
            primaryButtonText={"Cancel"}
            handleMessageModalStatus={this.handleMessageModalStatus}
            onModalClose={this.onModalClose}
          />
        );
      case "PoTemplate":
        return (
          <PoTemplate
            callLogId={this.props?.callLogId}
            onClose={this.props?.onClose}
            primaryButtonText={"Cancel"}
            handleMessageModalStatus={this.handleMessageModalStatus}
            onModalClose={this.onModalClose}
          />
        );
      case "Reports":
        return (
          <Reports
            callLogId={this.props?.callLogId}
            onClose={this.props?.onClose}
            primaryButtonText={"Cancel"}
            handleMessageModalStatus={this.handleMessageModalStatus}
            onModalClose={this.onModalClose}
          />
        );
      case "Shows":
        return (
          <ShowsModal
            callLogId={this.props?.callLogId}
            onSave={this.props.onSave}
            onClose={this.props?.onClose}
            selectedShow={this.props?.selectedShow}
            primaryButtonText={"Cancel"}
            mode={this.props.mode}
            handleMessageModalStatus={this.handleMessageModalStatus}
            onModalClose={this.onModalClose}
          />
        );
      case "Orientation":
        return (
          <OrientationModal
            callLogId={this.props?.callLogId}
            onClose={this.props?.onClose}
            primaryButtonText={"Cancel"}
            handleMessageModalStatus={this.handleMessageModalStatus}
            onModalClose={this.onModalClose}
            po_id={this.props?.po_id}
            url={this.props?.url}
          />
        );
      case "EditPORange":
        return (
          <EditPORange
            onClose={this.props?.onClose}
            onSave={this.props?.onSave}
            primaryButtonText={"Cancel"}
            showName={this.props.showName}
            selectedSeason={this.props.selectedSeason}
            showDetails={this.props.showDetails}
            handleMessageModalStatus={this.handleMessageModalStatus}
            className="editPorange"
          />
        );
      case "Vendor":
        return (
          <VendorModal
            callLogId={this.props?.callLogId}
            onClose={this.props?.onClose}
            primaryButtonText={"Cancel"}
            handleMessageModalStatus={this.handleMessageModalStatus}
            onModalClose={this.onModalClose}
            vendor={this.props?.vendor}
            onSave={this.props.onSave}
          />
        );
      case "ImportGL":
        return (
          <ImportGl
            callLogId={this.props?.callLogId}
            onClose={this.props?.onClose}
            primaryButtonText={"Cancel"}
            handleMessageModalStatus={this.handleMessageModalStatus}
            onModalClose={this.onModalClose}
          />
        );
      case "ExportPO":
        return (
          <ExportPO
            callLogId={this.props?.callLogId}
            onClose={this.props?.onClose}
            primaryButtonText={"Cancel"}
            handleMessageModalStatus={this.handleMessageModalStatus}
            onModalClose={this.onModalClose}
            onExport={this.props?.onExport}
            selectedEpisode={this.props?.selectedEpisode}
            selectedSeason={this.props?.selectedSeason}
          />
        );
      default:
        return null;
    }
  };

  // getClasses = () => {
  //     const { classes } = this.props;
  //     switch (this.props?.modalName) {
  //         case 'NewSet': return classes?.modalName;
  //         case 'RevampSet': return classes?.revampSet;
  //         default: return '';
  //     }
  // }

  handlePermissions = () => {
    let userPermissions = this.props?.userContext?.active_tenant?.permissions;
    let showSave = false;
    switch (this.props?.modalName) {
      case "ManageLookup":
        showSave = userPermissions?.isManageLookups
          ? userPermissions?.isManageLookups
          : false;
        break;
      case "NewSet":
        showSave = userPermissions?.isModifySet
          ? userPermissions?.isModifySet
          : false;
        break;
      case "RevampSet":
        showSave = userPermissions?.isModifySet
          ? userPermissions?.isModifySet
          : false;
        break;
      case "AddCatalogFile":
        showSave = userPermissions?.isModifySet
          ? userPermissions?.isModifySet
          : false;
        break;
      case "AddCadFiles":
        showSave = userPermissions?.isModifySet
          ? userPermissions?.isModifySet
          : false;
        break;
      case "EmailCatalog":
        showSave = userPermissions?.isViewSet
          ? userPermissions?.isViewSet
          : false;
        break;
      case "ExportCatalog":
        showSave = userPermissions?.isViewSet
          ? userPermissions?.isViewSet
          : false;
        break;
      case "PrintCatalog":
        showSave = userPermissions?.isViewSet
          ? userPermissions?.isViewSet
          : false;
        break;
      case "NewUser":
        showSave = userPermissions?.isManageUser
          ? userPermissions?.isManageUser
          : false;
        break;
      case "CreatePO":
        showSave = userPermissions?.isManageUser
          ? userPermissions?.isManageUser
          : false;
        break;
      case "PoTemplate":
        showSave = userPermissions?.isManageUser
          ? userPermissions?.isManageUser
          : false;
        break;
      case "Reports":
        showSave = userPermissions?.isManageUser
          ? userPermissions?.isManageUser
          : false;
        break;
      case "Shows":
        showSave = userPermissions?.isManageUser
          ? userPermissions?.isManageUser
          : false;
        break;
      case "Vendor":
        showSave = userPermissions?.isManageUser
          ? userPermissions?.isManageUser
          : false;
      case "ImportGL":
        showSave = userPermissions?.isManageUser
          ? userPermissions?.isManageUser
          : false;
        break;
    }
    return showSave;
  };

  getClassesForModal = () => {
    switch (this.props?.modalName) {
      case "NewSet":
        return "md";
      case "RevampSet":
        return "sm";
      case "AddCadFiles":
        return "md";
      case "AddCatalogFile":
        return "md";
      case "EmailCatalog":
        return "lg";
      case "PrintCatalog":
        return "lg";
      case "ExportCatalog":
        return "lg";
      case "NewUser":
        return "md";
      case "EditPORange":
        return "sm";
      case "ManageLookup":
        return "lg";
      case "CreatePO":
        return "sm";
      case "PoTemplate":
        return "lg";
      case "History":
        return "lg";
      case "Reports":
        return "sm";
      case "Shows":
        return "md";
      case "Vendor":
        return "sm";
      case "ImportGL":
        return "sm";
      case "Orientation":
        return "sm";
      default:
        return "md";
    }
  };

  getCustomClasses() {
    switch (this.props?.modalName) {
      case "ManageLookup":
        return "ModalContentContainer lookupModalContainer";
      case "History":
        return "ModalContentContainer historyModalContainer";
      case "NewUser":
        return "ModalContentContainer newUserModalContainer";
      case "EditPORange":
        return "ModalContentContainer edit-po-range";
      default:
        return "ModalContentContainer";
    }
  }

  render() {
    return (
      <div className={"MainViewModalContainer " + this.getWidthForModal()}>
        <div className={this.getCustomClasses()}>
          <MDBModal
            staticBackdrop
            closeOnEsc={false}
            tabIndex="-1"
            show={this.props?.open ? this.props?.open : false}
          >
            <MDBModalDialog size={this.getClassesForModal()}>
              <MDBModalBody>
                <MDBModalContent>
                  <div>
                    <MDBContainer fluid className="padding-hor-12">
                      <MDBRow className="ModalContent">
                        <MDBCol>
                          <MDBRow className="ModalHeader" id={"modal_header"}>
                            <MDBCol md={11} className="HeaderText">
                              {this.props.comfirmModalTitle}
                              {this.props?.mode === appConstants.LABEL_FIND
                                ? " - " +
                                  this.props?.currentGroupMember?.full_name
                                : ""}
                            </MDBCol>
                            {/* <MDBCol md={4} className="HeaderText"></MDBCol> */}
                            <MDBCol
                              md={1}
                              className="closeIcon"
                              onClick={this.onModalClose}
                            >
                              <CancelIcon style={{ fill: "white" }} />
                            </MDBCol>
                          </MDBRow>
                          {/* <MDBRow className="ModalBody" disabled={!this.handlePermissions()}> */}
                          <MDBRow className="ModalBody">
                            <MDBCol md={12} className="Content">
                              {this.getModalContent()}
                            </MDBCol>
                          </MDBRow>
                          <MDBRow className="footer"></MDBRow>
                        </MDBCol>
                      </MDBRow>
                    </MDBContainer>
                  </div>
                </MDBModalContent>
              </MDBModalBody>
            </MDBModalDialog>
            <MessageModal
              open={this.state.isMessageModalOpen}
              showError={this.state.haveUnsavedChanges || false}
              showFail={this.state.isPostingFailed || false}
              showSuccess={this.state.isPostingSuccess || false}
              primaryButtonText={"Ok"}
              secondaryButtonText={"Cancel"}
              hideCancel={
                this.state.isPostingFailed ||
                this.state.isPostingSuccess ||
                false
              }
              onConfirm={() => this.onModalConfirm()}
              handleClose={(e) => this.setState({ isMessageModalOpen: false })}
            />
          </MDBModal>
        </div>
      </div>
    );
  }
}

// export default withUserContext(withStyles(useStyles)(Modal));
export default withUserContext(Modal);
